import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createBlock(_component_q_checkbox, {
    "model-value": _ctx.control.data,
    label: _ctx.control.uischema.label || _ctx.control.label || undefined,
    "onUpdate:modelValue": _ctx.onChange,
    class: "primary",
    disable: _ctx.disable
  }, null, 8, ["model-value", "label", "onUpdate:modelValue", "disable"]))
}