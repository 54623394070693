<template>
  <div class="relative-position">
    <q-btn
      :icon="icon"
      class="btn--no-hover"
      @click="clickBtn"
      size="12px"
      :ripple="false"
      flat
      text-color="dark"
      :loading="loading"
      :disabled="loading || errorMessage?.length"
      round
    >
      <q-tooltip style="white-space: pre-line" v-if="errorMessage?.length">{{ errorMessage }}</q-tooltip>
      <q-tooltip v-else>{{ label }}</q-tooltip>
    </q-btn>
    <q-icon class="lock" :name="state === 'lock' ? 'mdi-lock' : 'mdi-lock-open-variant'" size="13px" />
  </div>
</template>

<script>
export default {
  name: 'OndemandButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, undefined],
    },
    label: {
      type: [String, undefined],
    },
    state: {
      type: String,
      default: 'lock',
    },
    errorMessage: {
      type: [String, undefined],
    },
  },
  methods: {
    clickBtn() {
      this.$emit(this.state === 'lock' ? 'lockClick' : 'unlockClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.lock {
  position: absolute;
  bottom: 2px;
  right: 0;
}
</style>
