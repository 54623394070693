<template>
  <div class="flex items-start">
    <div class="flex items-center event-name q-mr-lg">
      <q-icon :name="`engp:engp-ico-${actionData.type}`" size="16px" class="q-mr-xs"></q-icon>
      <b>{{ actionData.type }}</b>
    </div>
    <Status :workflow="actionData" :showTooltip="false" />
    <div class="event-message q-ml-lg">
      <div class="service-description-markdown full-width" v-html="dataMarkdown(actionData.message)"></div>
      <div v-if="actionData.variables && Object.keys(actionData?.variables)?.length">
        <ul>
          <li v-for="key in Object.keys(actionData?.variables)" :key="key">
            <b>{{ key }}:</b> {{ JSON.stringify(actionData?.variables[key]).replace(/"/g, '') }}
          </li>
        </ul>
      </div>
    </div>

    <div class="flex items-center q-ml-auto">
      <q-btn
        v-if="!actionData.variablesSchema && actionData.type === 'action' && actionData.status === 'pending'"
        icon="sym_r_play_arrow"
        class="action-btn q-ml-md"
        flat
        size="20px"
        @click="submit"
        :class="{ 'disable-btn': submitDisable || loading || formError?.length }"
        color="primary"
        no-caps
        :loading="loading"
        :disable="submitDisable || loading || formError?.length"
        ><q-tooltip max-width="480px" style="white-space: pre-line">{{
          formError?.length ? formError : actionData.message || 'Act'
        }}</q-tooltip></q-btn
      >
      <span v-if="actionData.status === 'resolved'" class="date flex items-center">
        <q-icon name="engp:engp-ico-updated" size="15px" class="q-mr-xs q-ml-md">
          <q-tooltip>Updated at {{ $options.dayjs(actionData.updatedAt).format('DD.MM.YYYY HH:mm') }}</q-tooltip>
        </q-icon>
      </span>
      <q-icon
        name="engp:engp-ico-copy"
        @click.stop="copyUuid(actionData.uuid)"
        size="16px"
        class="q-mx-md cursor-pointer"
      >
        <q-tooltip>Copy UUID</q-tooltip>
      </q-icon>
    </div>
    <div
      class="form-holder full-width q-mt-md"
      v-if="actionData.variablesSchema && actionData.type === 'action' && actionData.status === 'pending'"
    >
      <div class="form-container">
        <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mb-md">
          <span class="flex items-center" v-for="(error, key) in generalErrors" :key="key">
            <q-icon name="error" class="q-mr-xs" size="16px"></q-icon>{{ error.message }}
          </span>
        </q-banner>
        <JsonForms
          :schema="actionData.variablesSchema"
          :uiSchema="uiSchema"
          :data="formData"
          :additionalErrors="errors"
          @change="validateForm"
          :renderers="renderers"
        />
        <q-btn
          color="primary"
          text-color="dark"
          class="q-mt-lg"
          @click="submit"
          label="Submit"
          :loading="loading"
          no-caps
          :disable="
            submitDisable ||
            loading ||
            Boolean(formErrors.length) ||
            Boolean(errors.length) ||
            Boolean(generalErrors.length)
          "
        >
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import { JsonForms } from '@jsonforms/vue';
import { copyToClipboard } from 'quasar';
import dayjs from 'dayjs';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import { preResolveAction, resolveAction } from '../api';
import Status from './Status.vue';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

marked.setOptions({ renderer });

const renderers = [...quasarRenderers];

export default {
  name: 'ActionItem',
  dayjs,
  copyToClipboard,
  props: {
    actionData: {
      type: Object,
    },
  },
  emits: ['resolve'],
  components: {
    JsonForms,
    Status,
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Action UUID was copied');
      });
    },
    dataMarkdown(data) {
      return marked(data);
    },
    async validateForm(value) {
      this.formErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loading = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          const data = {
            subscriptionUuid: this.actionData.uuid,
            variables: this.formData,
          };
          try {
            await preResolveAction(data);
            this.errors = [];
            this.generalErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.errors = [];
              this.generalErrors = [];
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loading = false;
          }
        }, 3000);
      }
    },

    async submit() {
      this.loading = true;
      try {
        const data = {
          subscriptionUuid: this.actionData.uuid,
          variables: this.formData,
        };
        await resolveAction(data);
        this.$notify('positive', 'Success', 'Subscription was resolved successfull.');
        this.$emit('resolve');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (!this.actionData?.variablesSchema || Object.values(this.actionData?.variablesSchema).length === 0) {
      const authData = {
        subscriptionUuid: this.actionData.uuid,
        variables: null,
      };
      preResolveAction(authData)
        .then(() => {
          this.formError = undefined;
          this.submitDisable = false;
        })
        .catch((error) => {
          this.formError = error.message;
        });
    }
  },
  data() {
    return {
      formError: undefined,
      formErrors: [],
      errors: [],
      generalErrors: [],
      validationTimeout: undefined,
      renderers: Object.freeze(renderers),
      formData: {},
      loading: false,
      submitDisable: true,
      uiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'control',
            scope: '#',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.event-message {
  max-width: 45%;
}
.q-banner {
  min-height: 38px;
}
.q-field__bottom {
  padding: 2px 0 0 2px;
}
.status {
  display: flex;
  text-transform: capitalize;
  align-items: center;

  span {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $grey-6;

    &.resolved {
      background-color: $green;
    }

    &.pending {
      background-color: $orange;
    }

    &.cancelled {
      background-color: $grey-6;
    }
  }
}
.action-btn {
  margin: 0;
  padding: 0;
  min-height: 20px;

  &.disable-btn {
    opacity: 0.4 !important;
  }
}
.form-holder {
  @media (max-width: $breakpoint-md-max) {
    order: 4;
  }
}
.form-container {
  max-width: 480px;
  margin: 0 auto;
}
</style>
