<template>
  <q-expansion-item
    class="event bg-white btn--no-hover"
    :class="{
      error: eventData.isError,
      'bg-grey-3': eventStatus.status === 'cancelled',
      expanded: expand,
      collapsed: !expand,
    }"
    hide-expand-icon
    v-model="expand"
  >
    <template v-slot:header>
      <div class="full-width flex items-start">
        <div class="flex items-center event-name q-mr-lg">
          <q-icon :name="`engp:engp-ico-${eventData.type}`" size="16px" class="q-mr-xs"></q-icon>
          <b>{{ eventData.type }}</b>
          <q-icon v-if="eventData.isError" name="cancel" class="q-ml-xs" color="negative" size="xs"></q-icon>
        </div>
        <Status v-if="eventData?.subscriptions.length" :showTooltip="false" :workflow="eventStatus" class="q-mr-md" />
        <div class="service-description-markdown event-message" v-html="dataMarkdown(eventData.message)"></div>

        <div class="flex items-center q-ml-auto">
          <span v-if="eventData.type === 'timer'">
            <q-circular-progress
              :value="progress"
              size="18px"
              :thickness="0.3"
              track-color="grey-4"
              color="primary"
              class="q-mr-md"
            >
            </q-circular-progress>
            <q-tooltip>Ends at {{ finishTime }}</q-tooltip>
          </span>

          <span class="date flex items-center">
            <q-icon name="engp:engp-ico-clock" size="16px" class="q-mr-xs">
              <q-tooltip>Created at {{ $options.dayjs(eventData.createdAt).format('DD.MM.YYYY HH:mm') }}</q-tooltip>
            </q-icon>
          </span>
          <q-icon name="engp:engp-ico-copy" @click.stop="copyUuid(eventData.uuid)" size="16px" class="q-mx-md">
            <q-tooltip>Copy UUID</q-tooltip>
          </q-icon>
          <q-icon
            v-if="eventData.type === 'subscription' || eventData.events.length"
            class="event-arrow"
            size="sm"
            name="keyboard_arrow_down"
          ></q-icon>
          <sup>
            <q-icon v-if="eventData?.hasChildError" name="cancel" class="q-mb-md" color="negative" size="12px"></q-icon>
          </sup>
        </div>
      </div>
    </template>
    <div v-if="eventData?.subscriptions.length || eventData?.events.length" class="q-mb-sm q-px-md q-pb-md">
      <div
        v-for="subscription in eventData?.subscriptions"
        class="event-item q-mb-xs q-px-lg q-py-lg bg-white"
        :class="{ 'bg-grey-2': subscription.status === 'cancelled' }"
        :key="subscription.uuid"
      >
        <div>
          <action :actionData="subscription" @resolve="reload()" />
        </div>
      </div>
      <div v-if="eventData?.events?.length">
        <event v-for="event in eventData.events" :key="event.uuid" class="child-event" :eventData="event"></event>
      </div>
    </div>
  </q-expansion-item>
</template>

<script>
import dayjs from 'dayjs';
import { marked } from 'marked';
import { copyToClipboard } from 'quasar';
import emitter from 'tiny-emitter/instance';
import Action from './Action.vue';
import Status from './Status.vue';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

marked.setOptions({ renderer });

export default {
  dayjs,
  copyToClipboard,
  props: {
    eventData: {
      type: Object,
    },
  },
  name: 'EventItem',
  emits: ['resolve', 'reload'],
  components: {
    Action,
    Status,
  },
  created() {
    emitter.on('expandCollapseAll', (flag) => {
      this.expand = flag;
    });
  },
  unmounted() {
    emitter.off('expandCollapseAll');
  },
  data() {
    return {
      expand: false,
      progress: 5,
      progressInterval: null,
    };
  },
  mounted() {
    this.progressInterval = setInterval(this.updateProgress, 1000);
  },
  beforeUnmount() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  },
  computed: {
    finishTime() {
      const createdAtDate = new Date(this.eventData.createdAt);
      const finishTimestamp = createdAtDate.getTime() + this.eventData?.duration * 1000;
      const finishDate = new Date(finishTimestamp);
      return dayjs(finishDate).format('DD.MM.YYYY HH:mm');
    },
    eventStatus() {
      const eventStatus = {
        status: undefined,
      };
      if (this.eventData.subscriptions?.find((i) => i.cancelled)) {
        eventStatus.status = 'cancelled';
      }
      if (this.eventData.subscriptions?.find((i) => i.status === 'resolved')) {
        eventStatus.status = 'resolved';
      }
      if (this.eventData.subscriptions?.find((i) => i.status === 'pending')) {
        eventStatus.status = 'pending';
      }

      return eventStatus;
    },
  },
  methods: {
    updateProgress() {
      const now = new Date();
      const elapsed = (now - new Date(this.eventData.createdAt)) / 1000;
      this.progress = Math.min((elapsed / this.eventData.duration) * 100, 100);
      if (this.progress >= 100) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Subscription UUID was copied');
      });
    },
    reload() {
      this.$emit('reload');
    },
    dataMarkdown(data) {
      return marked(data);
    },
  },
};
</script>

<style lang="scss">
.progress-container {
  width: 100%;
  max-width: 320px;
  text-align: center;
}

.progress-bar-background {
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 10px;
}

.progress-bar-foreground {
  height: 100%;
  background-color: #73e1a5;
  border-radius: 10px;
}
.event.expanded > .q-expansion-item__container {
  .event-arrow {
    transform: rotate(180deg);
  }
}
.event.collapsed > .q-expansion-item__container {
  .event-arrow {
    transform: rotate(0deg);
  }
}
.event {
  border-radius: 15px;
  &.error {
    background-color: #ffe8e8 !important;
  }

  .event-arrow {
    transition: all 0.5s;
  }

  .service-description-markdown {
    font-size: 14px;
  }

  .event-message,
  .timer-holder {
    max-width: 59%;
    width: 100%;

    @media (max-width: $breakpoint-md-max) {
      max-width: 100%;
      width: 100%;
      order: 4;
      margin-top: 10px;
      text-align: center;
      margin-left: 0;
    }
  }

  .q-item {
    padding: 24px;
  }

  .event-name {
    min-width: 100px;
    text-transform: capitalize;
  }

  .event-item,
  .child-event {
    border: 1px solid $grey-5;
    border-radius: 15px;
    margin-bottom: 8px;
  }
}
</style>
