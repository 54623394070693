import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }
const _hoisted_2 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_time = _resolveComponent("q-time")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createBlock(_component_q_input, {
        key: 0,
        outlined: "",
        class: "input-form",
        label: _ctx.control.uischema.label || undefined,
        "model-value": _ctx.control.data,
        rules: [
      (v) => !v || new RegExp(_ctx.control?.schema?.pattern || '').test(v) || 'No valid value',
      (v) => !v || new RegExp(_ctx.control.errors || '').test(v) || _ctx.control.errors,
      (v) => _ctx.validateMinTime(v) || 'Date/time must be at least 1 hour from now'
    ],
        "reactive-rules": "",
        hint: _ctx.control.description,
        disable: !_ctx.control.enabled,
        "onUpdate:modelValue": _ctx.onChange,
        clearable: "",
        dense: "",
        "bg-color": "white"
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: "event",
            class: "cursor-pointer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_popup_proxy, {
                style: {"z-index":"9999999"},
                cover: "",
                "transition-show": "scale",
                "transition-hide": "scale"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_date, {
                    "model-value": _ctx.control.data,
                    "onUpdate:modelValue": _ctx.onChange,
                    mask: "YYYY-MM-DD HH:mm:ss"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _withDirectives(_createVNode(_component_q_btn, {
                          label: "Close",
                          color: "primary",
                          flat: ""
                        }, null, 512), [
                          [_directive_close_popup]
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["model-value", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        append: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: "access_time",
            class: "cursor-pointer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_popup_proxy, {
                style: {"z-index":"9999999"},
                cover: "",
                "transition-show": "scale",
                "transition-hide": "scale"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_time, {
                    "model-value": _ctx.control.data,
                    "onUpdate:modelValue": _ctx.onChange,
                    "with-seconds": "",
                    mask: "YYYY-MM-DD HH:mm:ss"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _withDirectives(_createVNode(_component_q_btn, {
                          label: "Close",
                          color: "primary",
                          flat: ""
                        }, null, 512), [
                          [_directive_close_popup]
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["model-value", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label", "model-value", "rules", "hint", "disable", "onUpdate:modelValue"]))
    : _createCommentVNode("", true)
}