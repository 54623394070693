<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div v-if="loadData" class="widget-section">
      <div class="flex items-center justify-between q-mb-md block-label">
        <span class="label-block">Environments:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-md btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          style="min-height: 30px"
          :disabled="addEnvError?.length"
          :ripple="false"
          @click="showForm = true"
        >
          + Add Environment
          <q-tooltip v-if="addEnvError?.length">{{ addEnvError }}</q-tooltip>
        </q-btn>
      </div>
      <div v-if="showForm" class="env-form">
        <div class="flex items-start no-wrap">
          <div class="full-width">
            <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mt-sm q-mb-sm">
              <span class="flex items-center" v-for="(error, key) in generalErrors" :key="key">
                <q-icon name="error" class="q-mr-xs" size="16px"></q-icon>{{ error.message }}
              </span>
            </q-banner>
            <div class="hide-label">
              <json-forms
                :data="formData"
                :renderers="renderers"
                :schema="schema"
                :uischema="uiSchema"
                @change="onChange"
              />
            </div>

            <json-forms
              v-if="newEnvs.length && !details"
              :data="formDataDetails"
              :renderers="renderers"
              :schema="detailsSchema"
              :uischema="detailsUiSchema"
              @change="validateDetails"
            />
          </div>

          <div class="btns flex items-center justify-end q-ml-auto q-mt-lg">
            <q-btn
              @click="send"
              no-caps
              text-color="dark"
              :loading="loadSend"
              :disabled="
                environments.length === formData.environments.length ||
                storedEnvironments().length === formData.environments.length ||
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(detailsFormErrors.length) ||
                Boolean(errors.length) ||
                Boolean(generalErrors.length)
              "
              color="primary"
              >Save</q-btn
            >
            <q-btn @click="closeForm" :ripple="false" class="btn--no-hover" no-caps flat>Cancel</q-btn>
          </div>
        </div>
      </div>
      <ul v-if="environments.length || storedEnvironments().length">
        <li class="q-mb-xs" v-for="(environment, key) in environments" :key="key">
          {{ environment.value }}
          <StatusCell showLabel :status="environment.status" class="q-ml-auto" />
          <WorkflowsBtn
            class="q-ml-md"
            @showWorkflow="showWorkflow($event, environment.id)"
            :relatedWorkflowStatuses="relatedWorkflowStatuses.find((i) => i.id === environment.id)?.statuses || []"
          />
        </li>
        <li class="q-mb-xs" v-for="(environment, key) in storedEnvironments()" :key="key">
          {{ environment }}
          <q-spinner size="18px" class="q-ml-auto"> </q-spinner>
          <q-tooltip>Workflow in the process of creation... </q-tooltip>
        </li>
      </ul>
    </div>
    <div class="widget-section q-mt-lg">
      <div class="flex items-center justify-between q-mb-md">
        <span class="label-block">Additional Information:</span>
      </div>
      <ul class="details">
        <li>
          <q-tooltip v-if="!details && (environments?.length || storedEnvironments?.length)"
            >Workflow in the process of creation...
          </q-tooltip>
          <b>STD Prefix:</b>
          <div class="q-ml-auto">
            <q-spinner v-if="!details && (environments?.length || storedEnvironments?.length)" size="18px"></q-spinner>
            <span v-else>{{ details?.stdPrefix || 'n/a' }}</span>
          </div>
        </li>
        <li>
          <q-tooltip v-if="!details && (environments?.length || storedEnvironments?.length)"
            >Workflow in the process of creation...
          </q-tooltip>
          <b>Business Domain:</b>
          <div class="q-ml-auto">
            <q-spinner v-if="!details && (environments?.length || storedEnvironments?.length)" size="18px"></q-spinner>
            <span v-else>{{ details?.businessDomain || 'n/a' }}</span>
          </div>
        </li>
        <li>
          <q-tooltip v-if="!details && (environments?.length || storedEnvironments?.length)"
            >Workflow in the process of creation...
          </q-tooltip>
          <b>External Application:</b>
          <div class="q-ml-auto">
            <q-spinner v-if="!details && (environments?.length || storedEnvironments?.length)" size="18px"></q-spinner>
            <span v-else
              ><q-icon v-if="details?.externalApp" name="sym_o_check" size="sm"></q-icon>
              <q-icon v-else name="sym_o_remove" size="sm"></q-icon
            ></span>
          </div>
        </li>
        <li>
          <q-tooltip v-if="!details && (environments?.length || storedEnvironments?.length)"
            >Workflow in the process of creation...
          </q-tooltip>
          <b>User List:</b>
          <div class="q-ml-auto">
            <q-spinner v-if="!details && (environments?.length || storedEnvironments?.length)" size="18px"></q-spinner>
            <span v-else>{{ details?.userlist?.join(', ') || 'n/a' }}</span>
          </div>
        </li>
      </ul>
    </div>
  </q-page>
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import StatusCell from './registration-network/components/StatusCell.vue';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import {
  getRegistration,
  subValWorkflow,
  getBluePrint,
  validateWorkFlow,
  createWorkFlow,
  getRelatedWorkflowsStatuses,
} from '../api/api';
import { supportCntrlClick } from '@/shared/helpers';
import WorkflowsBtn from '@/shared/components/WorkflowsBtn.vue';

const renderers = [...quasarRenderers];

export default {
  name: 'RegistrationMas',
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  components: {
    JsonForms,
    StatusCell,
    WorkflowsBtn,
  },
  computed: {
    newEnvs() {
      return this.formData.environments
        .filter((env) => !this.environments.find((e) => env === e.value))
        .filter((env) => !this.storedEnvironments().find((e) => env === e));
    },
  },
  data() {
    return {
      submitDisable: true,
      renderers: Object.freeze(renderers),
      schema: {
        description: 'A set of attributes defining the state of an object',
        type: 'object',
        properties: {
          environments: {
            type: 'array',
            uniqueItems: true,
            maxItems: 3,
            items: {
              type: 'string',
              enum: ['NONPROD', 'PROD'],
            },
          },
        },
      },
      showForm: false,
      addEnvError: undefined,
      uiSchema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/environments',
          },
        ],
      },
      formData: {
        environments: [],
      },
      formDataDetails: {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      detailsUiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/std_prefix',
            label: 'STD Prefix',
            class: 'col-5',
          },
          {
            type: 'Control',
            scope: '#/properties/business_domain',
            label: 'Business Domain',
            class: 'col-5',
          },
          {
            type: 'Control',
            scope: '#/properties/external_app',
            label: 'External App',
            class: 'col-2',
          },
          {
            type: 'Control',
            scope: '#/properties/userlist',
            label: 'User list',
            class: 'col-12',
            options: {
              itemLabel: 'User swissre ID',
            },
          },
        ],
      },
      detailsSchema: {},
      details: undefined,
      loadData: false,
      validationTimeout: undefined,
      formErrors: [],
      detailsFormErrors: [],
      errors: [],
      generalErrors: [],
      loadSend: false,
      relatedWorkflowStatuses: [],
    };
  },
  methods: {
    closeForm() {
      this.showForm = false;
      this.formData.environments = [];
      this.formDataDetails = {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      };
      this.generalErrors = [];
    },
    async send() {
      this.loadSend = true;
      const promises = this.newEnvs.map((env) => {
        const data = {
          workflowId: 'engprt.onboard-application-default',
          inputVariables: {
            digitalServiceApmId: this.registrationData.digitalServiceApm,
            infraServiceApmId: this.registrationData.infrastructureServiceApm,
            environmentId: env,
          },
        };
        return createWorkFlow(data)
          .then(() => {
            this.$notify('positive', 'Success', `${env} environment was added successfull.`);
            let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};

            storedData[this.registrationId] = storedData[this.registrationId] || [];
            if (!storedData[this.registrationId].includes(env)) {
              storedData[this.registrationId].push(env);
            }
            localStorage.setItem('registrationData', JSON.stringify(storedData));
          })
          .catch((error) => {
            this.$notify('negative', 'Error', error.message);
          });
      });
      if (Object.values(this.formDataDetails).length > 1) {
        const detailsPromise = createWorkFlow({
          workflowId: 'engprt.post-onboarding-mas',
          inputVariables: this.formDataDetails,
        })
          .then(() => {
            this.$notify('positive', 'Success', 'The post onboarding process started successfull.');
          })
          .catch((error) => {
            this.$notify('negative', 'Error', error.message);
          });
        promises.push(detailsPromise);
      }

      await Promise.all([...promises]).finally(async () => {
        this.loadSernd = false;
        this.closeForm();
        await this.load();
      });
    },
    validateDetails(value) {
      this.detailsFormErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formDataDetails) && !value.errors.length) {
        this.detailsFormErrors = value.errors;
        this.formDataDetails = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          try {
            const workFlowData = {
              workflowId: 'engprt.post-onboarding-mas',
              inputVariables: this.formDataDetails,
            };
            await validateWorkFlow(workFlowData);
            this.errors = [];
            this.generalErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.errors = [];
              this.generalErrors = [];
              this.submitDisable = true;
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loadSend = false;
          }
        }, 1500);
      }
    },
    onChange(value) {
      this.formErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          const promises = this.newEnvs.map(async (env) => {
            return validateWorkFlow({
              workflowId: 'engprt.onboard-application-default',
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                infraServiceApmId: this.registrationData.infrastructureServiceApm,
                environmentId: env,
              },
            })
              .then(() => {
                this.errors = [];
                this.generalErrors = [];
                this.submitDisable = false;
              })
              .catch((error) => {
                if (error?.response?.data?.errors?.length) {
                  this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
                  this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
                } else {
                  this.$notify('negative', 'Error', error.message);
                  this.errors = [];
                  this.generalErrors = [];
                  clearTimeout(this.validationTimeout);
                }
              });
          });
          await Promise.all([...promises]).finally(() => {
            this.loadSend = false;
          });
        }, 3000);
      }
    },
    showWorkflow(event, id) {
      supportCntrlClick(event, `/workflows?itemId=${id}`);
    },
    storedEnvironments() {
      const storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
      return storedData[this.registrationId] || [];
    },
    async load() {
      this.$showLoading();
      this.loadData = false;
      try {
        const data = await getRegistration(this.registrationId, true, true);
        this.formData.environments = data.environments.map((env) => env.value);
        this.environments = data.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));
        this.details = data && data?.details ? data?.details[0] : undefined;

        let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
        let storedEnvs = storedData[this.registrationId] || [];

        storedData[this.registrationId] = storedEnvs.filter((env) => !this.environments.some((e) => e.value === env));

        if (storedData[this.registrationId].length === 0) {
          delete storedData[this.registrationId];
        }

        this.formData.environments = [...new Set([...this.formData.environments, ...storedEnvs])];

        localStorage.setItem('registrationData', JSON.stringify(storedData));

        subValWorkflow({
          workflowId: `engprt.onboard-application-default`,
          inputVariables: {
            digitalServiceApmId: this.registrationData.digitalServiceApm,
            infraServiceApmId: this.registrationData.infrastructureServiceApm,
          },
          subSchemaId: 'add-button',
        })
          .then(() => {
            this.addEnvError = undefined;
          })
          .catch((error) => {
            this.addEnvError = error.message;
          });
        const relatedWorkflowsPrmises = this.environments.map((env) => {
          return getRelatedWorkflowsStatuses(env.id).then((response) => {
            this.relatedWorkflowStatuses.push({ id: env.id, statuses: response });
          });
        });
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadData = true;
        this.$hideLoading();
      }
    },
  },
  async mounted() {
    const { inputVariablesSchema: masSchema } = await getBluePrint('engprt.post-onboarding-mas');
    delete masSchema.$async;
    delete masSchema.$schema;
    this.detailsSchema = masSchema;
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
.label-block {
  font-size: 20px;
  font-weight: bold;
}
.btns {
  min-width: 250px;
}
.env-form {
  background-color: var(--q-accent);
  padding-top: 8px;
  margin-bottom: 15px;
  border-top: 1px solid $grey-4;
  border-bottom: 1px solid $grey-4;
}
.environments {
  &.read-widget {
    .block-label {
      margin-bottom: 5px;
    }
    li {
      padding: 10px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
  }

  li {
    width: calc(33.333% - 10px);
    margin: 0 5px;
    font-weight: bold;
    padding: 25px;
    border: 1px solid $grey-4;
    border-radius: 10px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-sm-max) {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
  }

  li {
    width: calc(33.333% - 10px);
    margin: 0 5px;
    font-weight: bold;
    padding: 25px;
    border: 1px solid $grey-4;
    border-radius: 10px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-sm-max) {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
.label-block {
  font-size: 20px;
  font-weight: bold;
}
.btns {
  min-width: 175px;
}
.env-form {
  background-color: var(--q-accent);
  padding: 18px 6px 10px;
  margin-bottom: 20px;
  border-top: 1px solid $grey-4;
  border-bottom: 1px solid $grey-4;
}
.details {
  li {
    font-weight: normal;
    margin-bottom: 10px;
  }
}
</style>
