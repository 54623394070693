<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <q-table
      :columns="cols"
      flat
      :rows="rows"
      class="external-dns-table"
      row-key="id"
      ref="workflowTable"
      binary-state-sort
      :rows-per-page-options="[5, 7, 10, 15, 20]"
      v-model:pagination="pagination"
      :loading="loading"
      @request="loadWorkflows"
    >
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            flat
            round
            size="12px"
            icon="sym_r_account_tree"
            style="width: 30px; height: 40px"
            class="btn--no-hover"
            :ripple="false"
            @click="goToWorkflow($event, props.row.uuid)"
          >
            <q-tooltip>Show workflow details</q-tooltip>
          </q-btn>
          <q-btn
            v-if="!['completed', 'abandoned', 'terminated'].includes(props.row.status)"
            flat
            round
            size="12px"
            icon="sym_r_refresh"
            style="width: 30px; height: 40px"
            :ripple="false"
            class="btn--no-hover"
            :loading="statusLoadingWorkflowId === props.row.uuid"
            @click="reloadStatus(props.row.uuid)"
          >
            <q-tooltip>Refresh workflow status</q-tooltip>
          </q-btn>
          <q-btn
            flat
            round
            size="9px"
            style="width: 30px; height: 40px"
            class="btn--no-hover"
            :ripple="false"
            icon="engp:engp-ico-copy"
            @click.stop="copyUuid(props.row.uuid)"
          >
            <q-tooltip>Copy uuid</q-tooltip>
          </q-btn>
          <q-btn
            v-if="props.row.status === 'not started'"
            flat
            round
            size="12px"
            icon="sym_r_delete"
            style="width: 30px; height: 40px"
            class="btn--no-hover"
            :ripple="false"
            @click="confirmAbandon(props.row.uuid)"
          >
            <q-tooltip>Abandon workflow</q-tooltip>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-workflowId="props">
        <q-td style="width: 30px" align="center" :props="props">
          <span class="instance-key cursor-pointer" @click="goToWorkflow($event, props.row.uuid)">{{
            props.row.workflowId
          }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-processInstanceKey="props">
        <q-td style="width: 30px" align="center" :props="props">
          <span class="instance-key cursor-pointer" @click="goToSco(props.row.processInstanceKey)">{{
            props.row.processInstanceKey
          }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td style="width: 30px" :props="props">
          <Status :workflow="props.row" tableView />
        </q-td>
      </template>
      <template v-slot:no-data>
        <div class="flex full-width column items-center q-py-lg">
          <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
          <span class="block" style="font-size: 14px">No items were found.</span>
        </div>
      </template>
      <template v-slot:top>
        <div class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
          <div class="col">
            <q-input
              v-model="filters.itemId"
              label="Item Id"
              outlined
              dense
              clearable
              @update:model-value="filterTable"
              @clear="reloadWorkflows()"
              class="q-mb-sm"
            ></q-input>
          </div>
          <div class="col">
            <q-select
              v-model="filters.status"
              label="Status"
              :options="[
                'not started',
                'inprogress',
                'pending',
                'completed',
                'error',
                'incident',
                'terminated',
                'abandoned',
              ]"
              options-dense
              outlined
              dense
              clearable
              @update:model-value="filterTable"
              @clear="reloadWorkflows()"
              class="q-mb-sm"
            ></q-select>
          </div>
          <div class="col">
            <q-input
              v-model="filters.workflowId"
              label="Workflow name"
              outlined
              dense
              clearable
              class="q-mb-sm"
              @update:model-value="filterTable"
              @clear="reloadWorkflows()"
            ></q-input>
          </div>
          <div class="col">
            <q-select
              v-model="filters.cancelling"
              :options="[
                { label: 'Cancelling', value: 'cancelling' },
                { label: 'Not cancelling', value: 'notCancelling' },
              ]"
              emit-value
              map-options
              options-dense
              label="Cancelling"
              outlined
              dense
              clearable
              class="q-mb-sm"
              @clear="reloadWorkflows()"
              @update:model-value="reloadWorkflows()"
            ></q-select>
          </div>
        </div>
      </template>
    </q-table>
  </q-page>

  <q-dialog v-model="showConfirm" class="single-service-page" persistent>
    <q-card style="min-width: 768px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Confirm abandon workflow</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="showConfirm = false"></q-btn>
      </q-toolbar>
      <q-card-actions class="q-pb-md">
        <q-btn
          color="primary"
          :loading="abandonLoading"
          @click="abandon"
          size="md"
          class="q-ml-sm"
          no-caps
          label="Yes"
        ></q-btn>
        <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="showConfirm = false">No</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { copyToClipboard } from 'quasar';
import { abandonWorkflow, getWorkFlows, refreshStatus } from './api';
import Status from './components/Status.vue';
import { supportCntrlClick } from '@/shared/helpers';

export default {
  name: 'WorkFlows',
  components: {
    Status,
  },
  data() {
    return {
      statusLoadingWorkflowId: undefined,
      abandonLoading: false,
      showConfirm: false,
      currentWorkfowUuid: undefined,
      loading: false,
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 10,
      },
      filters: {
        itemId: undefined,
        cancelling: undefined,
        workflowId: undefined,
        status: undefined,
      },
      rows: [],
      timeout: undefined,
      cols: [
        {
          label: 'Status',
          name: 'status',
          field: 'status',
          align: 'left',
        },
        {
          label: 'Workflow name',
          name: 'workflowId',
          field: 'workflowId',
          align: 'left',
        },
        {
          label: 'Actor',
          name: 'actor',
          field: 'actor',
          align: 'left',
        },
        {
          label: 'Created at',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Updated at',
          name: 'updatedAt',
          field: 'updatedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Process Instance Key',
          name: 'processInstanceKey',
          field: 'processInstanceKey',
          align: 'left',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'right',
        },
      ],
    };
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Workflow UUID was copied');
      });
    },
    async abandon() {
      this.abandonLoading = true;
      try {
        const { uuid, status } = await abandonWorkflow(this.currentWorkfowUuid);
        const updWorkfow = this.rows.find((workflow) => workflow.uuid === uuid);
        updWorkfow.status = status;
        this.$notify('positive', 'Success', 'Workflow was abandon successfull.');
        this.showConfirm = false;
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.abandonLoading = false;
      }
    },
    confirmAbandon(uuid) {
      this.currentWorkfowUuid = uuid;
      this.showConfirm = true;
    },
    async reloadStatus(id) {
      this.statusLoadingWorkflowId = id;
      try {
        const { uuid, status } = await refreshStatus(id);
        const updWorkfow = this.rows.find((workflow) => workflow.uuid === uuid);
        updWorkfow.status = status;
        this.$notify('positive', 'Success', 'Workflow status refreshed.');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.statusLoadingWorkflowId = undefined;
      }
    },
    goToWorkflow(event, uuid) {
      supportCntrlClick(event, `/workflows/${uuid}`);
    },
    goToSco(id) {
      window.open(`${process.env.VUE_APP_OASIS_URL}/${id}`, '_blank');
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.$refs.workflowTable.requestServerInteraction();
      }, 1500);
    },
    reloadWorkflows() {
      this.$refs.workflowTable.requestServerInteraction();
    },
    async loadWorkflows(props) {
      this.loading = true;
      try {
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { itemId, cancelling, workflowId, status } = this.filters;
        const { count, data } = await getWorkFlows(
          limit,
          skip,
          sortBy,
          descending,
          itemId,
          workflowId,
          status,
          cancelling,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.page = props.pagination.page;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (this.$route.query.itemId) {
      this.filters.itemId = this.$route.query.itemId;
    }
    try {
      this.reloadWorkflows();
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    }
  },
};
</script>

<style lang="scss" scoped>
.instance-key {
  color: $blue;
}
</style>
