<template>
  <ul class="environments" v-if="environments.length || storedEnvironments.length">
    <li class="q-mb-xs" v-for="(environment, key) in environments" :key="key">
      <div class="flex items-center">
        {{ environment.value }}
        <StatusCell showLabel :status="environment.status" class="q-ml-auto" />
        <WorkflowsBtn
          class="q-ml-md"
          @showWorkflow="showWorkflow($event, environment.id)"
          :relatedWorkflowStatuses="relatedWorkflowStatuses.find((i) => i.id === environment.id)?.statuses || []"
        />
        <q-btn
          v-if="mode === 'edit'"
          flat
          round
          size="12px"
          icon="sym_r_edit"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
          :disabled="disableEditEnvs[environment.value]"
          @click="$emit('edit', environment)"
        >
          <q-tooltip max-width="480px" style="white-space: pre-line">{{
            disableEditEnvs[environment.value] ? disableEditEnvs[environment.value] : 'Edit'
          }}</q-tooltip>
        </q-btn>
      </div>

      <div class="env-details">
        <span>Admin Roles:</span>
        <ul v-if="environment?.details?.adminRole?.length">
          <li v-for="(role, key) in environment?.details?.adminRole" :key="key">{{ role }}</li>
        </ul>
        <span>Contributor Roles:</span>
        <ul v-if="environment?.details?.contributorRole?.length">
          <li v-for="(role, key) in environment?.details?.contributorRole" :key="key">{{ role }}</li>
        </ul>
        <span>Reader Roles:</span>
        <ul v-if="environment?.details?.readerRole?.length">
          <li v-for="(role, key) in environment?.details?.readerRole" :key="key">{{ role }}</li>
        </ul>
      </div>
    </li>
    <li class="q-mb-xs flex items-baseline" v-for="(environment, key) in storedEnvironments" :key="key">
      {{ environment }}
      <q-spinner size="18px" class="q-ml-auto q-mt-sm"> </q-spinner>
      <q-tooltip>Workflow in the process of creation... </q-tooltip>
    </li>
  </ul>
</template>

<script>
import StatusCell from '../../registration-network/components/StatusCell.vue';
import WorkflowsBtn from '@/shared/components/WorkflowsBtn.vue';
import { supportCntrlClick } from '@/shared/helpers';

export default {
  name: 'ServiceBusEnv',
  components: {
    StatusCell,
    WorkflowsBtn,
  },
  props: {
    environments: {
      type: Array,
    },
    relatedWorkflowStatuses: {
      type: Array,
    },
    storedEnvironments: {
      type: Array,
    },
    mode: {
      type: String,
      default: 'read',
    },
    disableEditEnvs: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    showWorkflow(event, id) {
      supportCntrlClick(event, `/workflows?itemId=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
ul.environments {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
}

ul.environments > li {
  width: calc(33.333% - 10px);
  margin: 0 5px;
  font-weight: bold;
  padding: 10px 25px;
  border: 1px solid $grey-4;
  border-radius: 10px;
  list-style-type: none;

  @media (max-width: $breakpoint-sm-max) {
    width: 100%;
    margin-bottom: 5px;
  }
}
.env-details {
  span {
    display: block;
    margin-top: 10px;
  }
  ul {
    margin: 0;
    padding: 0;

    li {
      font-weight: normal;
    }
  }
}
</style>
