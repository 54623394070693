import app from './app';
import notifications from './notifications';
import services from './services';
import serviceDesign from './service-design';
import templates from './templates';
import digitalServices from './digital-services';
import standards from './standards';
import banners from './banners';
import serviceStatus from './service-status';
import roles from './roles';

export default {
  app,
  notifications,
  services,
  serviceDesign,
  templates,
  digitalServices,
  standards,
  banners,
  serviceStatus,
  roles,
};
