<template>
  <div class="flex items-center">
    <slot></slot>
    <q-btn
      class="filter-icon q-ml-auto btn--no-hover"
      :class="{ active: showFilters }"
      icon="filter_alt"
      flat
      :ripple="false"
      @click="showFilters = !showFilters"
    >
      <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
    </q-btn>
    <q-btn
      v-if="mode === 'read'"
      flat
      size="md"
      style="width: 30px"
      icon="edit"
      class="btn--no-hover"
      @click="goToRegistration($event)"
    >
      <q-tooltip>Edit zones</q-tooltip>
    </q-btn>
  </div>
  <q-table
    :columns="cols"
    flat
    :rows="rows"
    class="external-dns-table"
    ref="cloudenteZones"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    binary-state-sort
    :rows-per-page-options="[5, 7, 10, 15, 20]"
    @request="loadZones"
  >
    <template v-slot:body-cell-lastChangeLink="props">
      <q-td :props="props">
        <a v-if="props.row.lastChangeLink" target="_blank" :href="props.row.lastChangeLink">{{
          props.row.lastChangeNumber || 'Change Request'
        }}</a>
        <span v-else>-</span>
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <WorkflowsBtn
          @showWorkflow="showWorkflow($event, props.row)"
          :loading="loading"
          :relatedWorkflowStatuses="relatedWorkflowStatuses.find((i) => i.id === props.row.id)?.statuses || []"
        />
        <q-btn
          v-if="mode === 'edit' && !props.row.deletedAt"
          flat
          round
          size="13px"
          icon="sym_o_compare_arrows"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
          :loading="loading"
          :disabled="loading || disableReassignIds.find((item) => item.id === props.row.id)"
          @click="$emit('reassign', props.row)"
        >
          <q-tooltip v-if="!loading" max-width="480px" style="white-space: pre-line">{{
            !!disableReassignIds.find((item) => item.id === props.row.id)
              ? disableReassignIds.find((item) => item.id === props.row.id)?.message
              : 'Reassign zone'
          }}</q-tooltip>
        </q-btn>
        <q-btn
          v-if="mode === 'edit' && !props.row.deletedAt"
          flat
          round
          size="12px"
          @click="$emit('confirmDeleteZone', props.row)"
          :loading="loading"
          :disabled="loading || disableDeleteIds.find((item) => item.id === props.row.id)"
          icon="sym_r_delete"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
        >
          <q-tooltip max-width="480px" style="white-space: pre-line" v-if="!loading">{{
            disableDeleteIds.find((item) => item.id === props.row.id)
              ? disableDeleteIds.find((item) => item.id === props.row.id).message
              : 'Delete'
          }}</q-tooltip>
        </q-btn>
        <q-btn
          flat
          round
          size="10px"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
          :ripple="false"
          icon="engp:engp-ico-copy"
          @click.stop="copyUuid(props.row.id)"
        >
          <q-tooltip>Copy uuid</q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:no-data>
      <div class="flex full-width column items-center q-py-lg">
        <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 14px">No items were found.</span>
      </div>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td style="width: 30px" :props="props">
        <StatusCell v-if="!props.row.deletedAt" :status="props.row.status" />
        <q-icon v-else name="close" color="grey" size="15px"></q-icon>
      </q-td>
    </template>
    <template v-slot:body-cell-tokenUrl="props">
      <q-td style="width: 30px" :props="props">
        <a v-if="props.row.tokenUrl" :href="props.row.tokenUrl" target="_blank">
          <q-icon name="sym_r_link" size="18px"></q-icon>
        </a>
        <span v-else>-</span>
      </q-td>
    </template>
    <template v-slot:top>
      <div v-if="showFilters" class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
        <div class="col">
          <q-select
            v-model="filters.status"
            :options="[
              { label: 'Completed', value: 'completed' },
              { label: 'In progress', value: 'inprogress' },
              { label: 'Failed', value: 'failed' },
              { label: 'Removed', value: 'removed' },
            ]"
            emit-value
            map-options
            options-dense
            @update:model-value="filterByStatus"
            label="Status"
            clearable
            outlined
            dense
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.name"
            label="Zone name"
            @update:model-value="filterTable"
            @clear="reloadTable()"
            outlined
            dense
            clearable
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-select
            v-model="filters.license"
            :options="[
              { label: 'Free', value: 'free' },
              { label: 'Enterprise', value: 'enterprise' },
              { label: 'China', value: 'china' },
            ]"
            emit-value
            map-options
            options-dense
            label="License"
            @update:model-value="reloadTable()"
            @clear="reloadTable()"
            outlined
            dense
            clearable
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-select
            v-model="filters.dns"
            :options="[
              { label: 'DNS enabled', value: 'enabled' },
              { label: 'DNS disabled', value: 'disabled' },
            ]"
            emit-value
            map-options
            options-dense
            @update:model-value="reloadTable()"
            @clear="reloadTable()"
            label="DNS"
            outlined
            dense
            clearable
            class="q-mb-sm"
          ></q-select>
        </div>
      </div>
    </template>
  </q-table>
</template>

<script>
import dayjs from 'dayjs';
import emitter from 'tiny-emitter/instance';
import { copyToClipboard } from 'quasar';
import { validateWorkFlow, subValWorkflow, getZones, getRelatedWorkflowsStatuses } from '../../api/api';
import StatusCell from '../registration-network/components/StatusCell.vue';
import { supportCntrlClick } from '@/shared/helpers';
import WorkflowsBtn from '@/shared/components/WorkflowsBtn.vue';

export default {
  name: 'ZonesTable',
  components: {
    StatusCell,
    WorkflowsBtn,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
    mode: {
      type: String,
      default: () => 'read',
    },
  },
  data() {
    return {
      cols: [
        {
          label: 'Status',
          name: 'status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Name',
          name: 'name',
          field: 'name',
          align: 'left',
        },
        {
          label: 'License',
          name: 'license',
          field: 'license',
          align: 'left',
        },
        {
          label: 'DNS',
          name: 'DNS',
          field: 'DNS',
          align: 'center',
        },
        {
          label: 'Created At',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Updated At',
          name: 'updatedAt',
          field: 'updatedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Change Request',
          name: 'lastChangeLink',
          field: 'lastChangeLink',
          align: 'center',
        },
        {
          label: 'Token URL',
          name: 'tokenUrl',
          field: 'tokenUrl',
          align: 'center',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'right',
        },
      ],
      rows: [],
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
      filters: {
        name: undefined,
        license: undefined,
        dns: undefined,
        status: undefined,
      },
      loading: false,
      showFilters: false,
      disableDeleteIds: [],
      disableReassignIds: [],
      timeout: undefined,
      relatedWorkflowStatuses: [],
    };
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Zone UUID was copied');
      });
    },
    goToRegistration(event) {
      supportCntrlClick(
        event,
        `/my-portfolio/${this.registrationData.digitalServiceApm}/registration/${this.registrationId}`,
      );
    },
    filterByStatus(value) {
      this.filters.status = value;
      if (value === 'removed') {
        this.cols.splice(6, 0, {
          label: 'Deleted At',
          name: 'deletedAt',
          field: 'deletedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
        });
      } else {
        if (this.cols.length === 10) {
          this.cols.splice(6, 1);
        }
      }
      this.reloadTable();
    },
    showWorkflow(event, record) {
      supportCntrlClick(event, `/workflows?itemId=${record.id}`);
    },
    async loadZones(props) {
      this.loading = true;
      try {
        this.relatedWorkflowStatuses = [];
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { name, license, dns, status } = this.filters;
        const { count, data } = await getZones(
          this.registrationId,
          limit,
          skip,
          sortBy,
          descending,
          name,
          license,
          dns,
          status,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.pagination.page = props.pagination.page;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
        if (this.mode === 'edit' && this.filters.status !== 'removed') {
          const deletePrevalPromises = this.rows.map(async (zone) => {
            const data = {
              workflowId: 'engprt.delete-cloudflare-zone',
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                id: zone.id,
              },
            };
            return validateWorkFlow(data).catch((res) => {
              this.disableDeleteIds.push({ id: zone.id, message: res.message });
            });
          });
          const movePrevalPromises = this.rows.map(async (zone) => {
            const { id } = zone;
            const data = {
              workflowId: 'engprt.move-cloudflare-zone',
              subSchemaId: 'move-button',
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                id,
              },
            };
            return subValWorkflow(data).catch((res) => {
              this.disableReassignIds.push({ id: data.inputVariables.id, message: res.message });
            });
          });
          await Promise.all([...deletePrevalPromises, ...movePrevalPromises]);
        }
        const relatedWorkflowsPrmises = this.rows.map((record) => {
          return getRelatedWorkflowsStatuses(record.id).then((response) => {
            this.relatedWorkflowStatuses.push({ id: record.id, statuses: response });
          });
        });
        await Promise.all([...relatedWorkflowsPrmises]);
      } finally {
        this.loading = false;
      }
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reloadTable();
      }, 1500);
    },
    reloadTable() {
      this.$refs.cloudenteZones.requestServerInteraction();
    },
  },
  mounted() {
    this.reloadTable();
  },
  created() {
    emitter.on('reloadZones', () => {
      this.reloadTable();
    });
  },
  unmounted() {
    emitter.off('reloadZones');
  },
};
</script>
