<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <section v-if="loadData" class="widget-section">
      <div class="flex items-center">
        <span class="label-block">Environments:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-auto btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          style="min-height: 30px"
          :ripple="false"
          @click="showCreateForm = true"
          :disabled="createEnvError?.length"
        >
          <q-tooltip v-if="createEnvError?.length">{{ createEnvError }}</q-tooltip>
          + Add environment
        </q-btn>
      </div>
      <div v-if="showCreateForm">
        <div class="update-form">
          <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mb-sm">
            <span class="flex items-center" v-for="(error, key) in generalErrors" :key="key">
              <q-icon name="error" class="q-mr-xs" size="16px"></q-icon>{{ error.message }}
            </span>
          </q-banner>
          <div class="flex full-width no-wrap">
            <JsonForms
              :renderers="renderers"
              :schema="schema"
              :data="formData"
              :uischema="uiSchema"
              :additionalErrors="createErrors"
              validationMode="ValidateAndShow"
              @change="validateForm"
            />
            <q-btn
              color="primary"
              size="md"
              class="q-ml-sm"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(createErrors.length) ||
                Boolean(generalErrors.length)
              "
              style="height: 38px"
              @click="sendData()"
              no-caps
              label="Submit"
            ></q-btn>

            <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeForm">Cancel</q-btn>
          </div>
        </div>
      </div>
      <AlibabaEnvironments :registrationData="registrationData" :registrationId="registrationId" mode="edit" />
    </section>
  </q-page>
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import emitter from 'tiny-emitter/instance';
import { getBluePrint, subValWorkflow, validateWorkFlow, createWorkFlow } from '../../api/api';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import AlibabaEnvironments from './AlibabaEnvironments.vue';

const renderers = [...quasarRenderers];

export default {
  name: 'RegistrationAlibaba',
  components: {
    JsonForms,
    AlibabaEnvironments,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      loadData: true,
      showCreateForm: false,
      formErrors: [],
      createErrors: [],
      generalErrors: [],
      loadSend: false,
      submitDisable: true,
      renderers: Object.freeze(renderers),
      validationTimeout: undefined,
      createEnvError: undefined,
      schema: {},
      formData: {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      uiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Environment',
            scope: '#/properties/environment',
          },
          {
            type: 'Control',
            label: 'Region',
            scope: '#/properties/region',
          },
          {
            type: 'Control',
            label: 'Alicloud ID',
            scope: '#/properties/alicloudId',
          },
          {
            type: 'Control',
            label: 'VPC ID',
            scope: '#/properties/vpcId',
          },
          {
            type: 'Control',
            label: 'Subnets',
            scope: '#/properties/subnets',
          },
        ],
      },
    };
  },
  async mounted() {
    const workflow = 'engprt.onboard-application-alibaba';
    const { inputVariablesSchema: schema } = await getBluePrint(workflow);
    delete schema.$async;
    delete schema.$schema;
    this.schema = schema;
    const subvalData = {
      workflowId: workflow,
      inputVariables: {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      subSchemaId: 'add-button',
    };
    this.loadData = false;
    this.$showLoading();
    subValWorkflow(subvalData)
      .then(() => {
        this.createEnvError = undefined;
      })
      .catch((error) => {
        this.createEnvError = error.message;
      })
      .finally(() => {
        this.loadData = true;
        this.$hideLoading();
      });
  },
  methods: {
    async validateForm(value) {
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          const workflowData = {
            workflowId: 'engprt.onboard-application-alibaba',
            inputVariables: this.formData,
          };
          try {
            await validateWorkFlow(workflowData);
            this.createErrors = [];
            this.generalErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.createErrors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.createErrors = [];
              this.generalErrors = [];
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loadSend = false;
          }
        }, 3000);
      }
    },
    async sendData() {
      try {
        const workflowData = {
          workflowId: 'engprt.onboard-application-alibaba',
          inputVariables: this.formData,
        };
        this.loadSend = true;
        await createWorkFlow(workflowData);
        this.$notify('positive', 'Success', 'Environment will be created.');
        setTimeout(() => {
          emitter.emit('reloadAlibabaEnvs');
          this.closeForm();
          this.loadSend = false;
        }, 2000);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
        this.loadSend = false;
      }
    },
    closeForm() {
      this.showCreateForm = false;
      this.formData = {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.env-form {
  width: 50%;
}
.label-block {
  font-size: 20px;
}
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
</style>
