
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, RendererProps, useJsonFormsControl } from '@jsonforms/vue';
import QInput from 'quasar/src/components/input/QInput.js';;

const controlRenderer = defineComponent({
  name: 'date-control-renderer',
  props: {
    ...rendererProps<ControlElement>(),
    dateCanBeEdit: { type: Boolean, default: false },
    isNewEl: { type: Boolean, default: false },
    dataElement: { type: Object },
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props);
  },
  data() {
    return {
      date: undefined,
    };
  },
  methods: {
    validateMinTime(value: any) {
      if (!value) return true; // Allow empty values (adjust if required)

      const inputTime = new Date(value).getTime();
      const nowPlusOneHour = Date.now() + 3600000; // Current time + 1 hour in milliseconds

      return inputTime >= nowPlusOneHour;
    },
    onChange(value: any) {
      this.handleChange(this.control.path, value);
    },
  },
});

export default controlRenderer;
