<template>
  <div class="flex" v-if="environments.length">
    <q-tabs
      v-model="envTab"
      @update:model-value="changeEnv"
      inline-label
      content-class="justify-start"
      class="q-mt-md widget-tabs"
      active-class="active-tab"
      indicator-color="transparent"
    >
      <q-tab v-for="item in environments" :name="item.value" :key="item.value">
        <span style="text-transform: capitalize; font-size: 12px">{{ item.value }}</span>
      </q-tab>
    </q-tabs>
    <q-btn
      class="filter-icon q-ml-auto btn--no-hover"
      :class="{ active: showFilters }"
      icon="filter_alt"
      flat
      :ripple="false"
      @click="showFilters = !showFilters"
    >
      <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
    </q-btn>
    <q-btn
      v-if="mode === 'read'"
      flat
      size="md"
      style="width: 30px"
      icon="edit"
      class="btn--no-hover"
      @click="goToRegistration($event)"
    >
      <q-tooltip>Edit vnets</q-tooltip>
    </q-btn>
  </div>
  <q-table
    :columns="cols"
    flat
    :rows="rows"
    class="external-dns-table"
    ref="vnetsTable"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    binary-state-sort
    :rows-per-page-options="[5, 7, 10, 15, 20]"
    @request="loadVnetRecords"
  >
    <template v-slot:no-data>
      <div class="flex full-width column items-center q-py-lg">
        <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 14px">No items were found.</span>
      </div>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td style="width: 30px" :props="props">
        <StatusCell v-if="!props.row.deletedAt" :status="props.row.status" />
        <q-icon v-else name="close" color="grey" size="15px"></q-icon>
      </q-td>
    </template>
    <template v-slot:body-cell-lastChangeLink="props">
      <q-td :props="props">
        <a v-if="props.row.lastChangeLink" target="_blank" :href="props.row.lastChangeLink">{{
          props.row.lastChangeNumber || 'Change Request'
        }}</a>
        <span v-else>-</span>
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <WorkflowsBtn
          @showWorkflow="showWorkflow($event, props.row)"
          :loading="loading"
          :relatedWorkflowStatuses="relatedWorkflowStatuses.find((i) => i.id === props.row.id)?.statuses || []"
        />
        <q-btn
          v-if="mode === 'edit' && !props.row.deletedAt"
          flat
          round
          size="12px"
          disabled
          icon="sym_r_delete"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
        >
          <q-tooltip>Vnet deletion not supported</q-tooltip>
        </q-btn>
        <q-btn
          flat
          round
          size="10px"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
          :ripple="false"
          icon="engp:engp-ico-copy"
          @click.stop="copyUuid(props.row.id)"
        >
          <q-tooltip>Copy uuid</q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:top>
      <div v-if="showFilters" class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
        <div class="col">
          <q-select
            v-model="filters.status"
            :options="[
              { label: 'Completed', value: 'completed' },
              { label: 'In progress', value: 'inprogress' },
              { label: 'Failed', value: 'failed' },
              { label: 'Removed', value: 'removed' },
            ]"
            emit-value
            map-options
            options-dense
            @update:model-value="filterByStatus"
            label="Status"
            clearable
            outlined
            dense
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.name"
            label="Name"
            outlined
            dense
            @update:model-value="filterTable('vnets')"
            @clear="reloadVnetsTable()"
            clearable
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-select
            v-model="filters.region"
            label="Region"
            :options="['West Europe', 'North Europe', 'Switzerland North', 'East US 2', 'Central US', 'Southeast Asia']"
            options-dense
            outlined
            dense
            clearable
            @update:model-value="reloadVnetsTable()"
            @clear="reloadVnetsTable()"
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.resourceGroup"
            label="Resource Group"
            outlined
            dense
            clearable
            @update:model-value="filterTable('vnets')"
            @clear="reloadVnetsTable()"
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-select
            v-model="filters.size"
            label="Size"
            :options="[8, 16, 32, 64, 128, 256]"
            outlined
            options-dense
            dense
            clearable
            @update:model-value="reloadVnetsTable()"
            @clear="reloadVnetsTable()"
            class="q-mb-sm"
          ></q-select>
        </div>
      </div>
    </template>
  </q-table>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import dayjs from 'dayjs';
import { copyToClipboard } from 'quasar';
import StatusCell from './StatusCell.vue';
import { getVnets, getRelatedWorkflowsStatuses } from '@/pages/registrations/api/api';
import { supportCntrlClick } from '@/shared/helpers';
import WorkflowsBtn from '@/shared/components/WorkflowsBtn.vue';

export default {
  name: 'VnetsTable',
  components: {
    StatusCell,
    WorkflowsBtn,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
    environments: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: () => 'read',
    },
  },
  data() {
    return {
      cols: [
        {
          label: 'Status',
          name: 'status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Name',
          name: 'vNetName',
          field: 'vNetName',
          align: 'left',
        },
        {
          label: 'Region',
          name: 'region',
          field: 'region',
          align: 'left',
        },
        {
          label: 'Resource Group',
          name: 'resourceGroup',
          field: 'resourceGroup',
          align: 'left',
        },
        {
          label: 'Size',
          name: 'size',
          field: 'size',
          align: 'left',
        },
        {
          label: 'Created At',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Updated At',
          name: 'updatedAt',
          field: 'updatedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Change Request',
          name: 'lastChangeLink',
          field: 'lastChangeLink',
          align: 'center',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'right',
        },
      ],
      rows: [],
      showFilters: false,
      loading: false,
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
      filters: {
        name: undefined,
        region: undefined,
        resourceGroup: undefined,
        size: undefined,
        status: undefined,
      },
      envTab: undefined,
      timeout: undefined,
      relatedWorkflowStatuses: [],
    };
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Vnet UUID was copied');
      });
    },
    goToRegistration(event) {
      supportCntrlClick(
        event,
        `/my-portfolio/${this.registrationData.digitalServiceApm}/registration/${this.registrationId}`,
      );
    },
    filterByStatus(value) {
      this.filters.status = value;
      if (value === 'removed') {
        this.cols.splice(7, 0, {
          label: 'Deleted At',
          name: 'deletedAt',
          field: 'deletedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
        });
      } else {
        if (this.cols.length === 10) {
          this.cols.splice(7, 1);
        }
      }
      this.reloadVnetsTable();
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reloadVnetsTable();
      }, 1500);
    },
    showWorkflow(event, record) {
      supportCntrlClick(event, `/workflows?itemId=${record.id}`);
    },
    async loadVnetRecords(props) {
      this.loading = true;
      try {
        this.relatedWorkflowStatuses = [];
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { name, resourceGroup, region, size, status } = this.filters;
        const { count, data } = await getVnets(
          this.registrationId,
          this.envTab,
          sortBy,
          descending,
          limit,
          skip,
          name,
          resourceGroup,
          size,
          region,
          status,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.pagination.page = props.pagination.page;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
        const relatedWorkflowsPrmises = this.rows.map((record) => {
          return getRelatedWorkflowsStatuses(record.id).then((response) => {
            this.relatedWorkflowStatuses.push({ id: record.id, statuses: response });
          });
        });
        await Promise.all([...relatedWorkflowsPrmises]);
      } finally {
        this.loading = false;
      }
    },
    reloadVnetsTable() {
      this.$refs.vnetsTable.requestServerInteraction();
    },
    changeEnv(env) {
      this.envTab = env;
      this.reloadVnetsTable();
      this.$emit('changeEnv', this.envTab);
    },
  },
  mounted() {
    if (this.environments.length) {
      this.envTab = this.environments[0].value;
      this.$emit('changeEnv', this.envTab);
      this.reloadVnetsTable();
    }
  },
  created() {
    emitter.on('reloadVnetsTable', () => {
      this.reloadVnetsTable();
    });
  },
  unmounted() {
    emitter.off('reloadVnetsTable');
  },
};
</script>

<style lang="scss" scoped>
.q-tabs {
  margin: 0 -3px;
}
.widget-tabs .q-tab {
  min-width: 150px;
  margin: 0 3px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #b8bcc6;
  &.active-tab {
    background-color: #edeff5;
  }
}
</style>
