<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <Links :infraService="infraService" />
  </div>
  <div v-if="!loadData" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="50px" color="primary"></q-spinner-gears>
  </div>
  <div
    v-else-if="loadData && !environments.length"
    class="flex full-width column items-center q-py-lg"
    style="padding: 30px 0"
  >
    <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
    <span class="block" style="font-size: 16px">No data</span>
  </div>
  <div v-else class="q-pt-sm q-mb-lg">
    <ServiceBusEnv :environments="environments" :relatedWorkflowStatuses="relatedWorkflowStatuses" />
  </div>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
</template>

<script>
import { getRegistration, getRelatedWorkflowsStatuses } from '@/pages/registrations/api/api';
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
import Links from './Links.vue';
import ServiceBusEnv from '@/pages/registrations/components/registration-service-bus/components/ServiceBusEnv.vue';

export default {
  name: 'ServiceBusWidget',
  components: {
    AccessProductsTable,
    ServiceBusEnv,
    Links,
  },
  props: {
    registrationId: { type: String },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadAccessProducts: { type: Boolean, default: () => false },
  },
  data() {
    return {
      relatedWorkflowStatuses: [],
      environments: [],
      loadData: false,
    };
  },
  async mounted() {
    try {
      const data = await getRegistration(this.registrationId, true, true);

      this.environments = data.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));

      this.environments.forEach((env) => {
        if (data.details?.length) {
          env.details = data.details.find((i) => i.environment === env.value);
        }
      });
      const relatedWorkflowsPrmises = this.environments.map((env) => {
        return getRelatedWorkflowsStatuses(env.id).then((response) => {
          this.relatedWorkflowStatuses.push({ id: env.id, statuses: response });
        });
      });

      await Promise.all([...relatedWorkflowsPrmises]);
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.loadData = true;
    }
  },
};
</script>
