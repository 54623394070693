<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <DigitalServicesTable owned />
    <DigitalServicesTable />
  </q-page>
</template>

<script>
import DigitalServicesTable from './components/DigitalServicesTable.vue';

export default {
  name: 'MyPortfolio',
  components: {
    DigitalServicesTable,
  },
};
</script>
