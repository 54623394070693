<template>
  <div class="digital-services-holder q-px-md q-py-md bg-white" style="margin-bottom: 50px">
    <div class="table-header flex items-center justify-between">
      <h5>{{ owned ? 'My' : 'Followed' }} Digital Services</h5>
      <q-btn
        class="filter-icon btn--no-hover"
        :class="{ active: showFilters }"
        icon="filter_alt"
        flat
        @click="showFilters = !showFilters"
      >
        <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
      </q-btn>
    </div>
    <div v-if="showFilters" class="flex no-wrap">
      <div class="row no-wrap q-col-gutter-xs full-width">
        <div class="col">
          <q-input
            v-model="filters.name"
            @update:model-value="filterTable"
            @clear="reloadTable"
            clearable
            dense
            outlined
            label="Name"
          ></q-input>
        </div>
        <div class="col">
          <q-input
            v-model="filters.apmId"
            @update:model-value="filterTable"
            @clear="reloadTable"
            clearable
            dense
            outlined
            label="APMID"
          ></q-input>
        </div>
        <div class="col">
          <q-select
            @update:model-value="reloadTable"
            @clear="reloadTable"
            v-model="filters.digitalServiceActive"
            clearable
            dense
            outlined
            label="State"
            options-dense
            map-options
            emit-value
            :options="[
              { label: 'Active', value: true },
              { label: 'Inactive', value: false },
            ]"
          ></q-select>
        </div>
        <div class="col">
          <q-select
            @update:model-value="reloadTable"
            @clear="reloadTable"
            v-model="filters.businessDomain"
            clearable
            dense
            outlined
            label="Business Domain"
            options-dense
            map-options
            emit-value
            :options="$options.BUSINESS_DOMAINS"
          ></q-select>
        </div>
        <div class="col">
          <q-select
            @update:model-value="reloadTable"
            @clear="reloadTable"
            v-model="filters.itscmCriticality"
            clearable
            dense
            outlined
            options-dense
            map-options
            emit-value
            label="ITSCM Criticality"
            :options="['0 - Highly Critical', '1 - Critical', '2 - High', '3 - Medium', '4 - Low']"
          ></q-select>
        </div>
      </div>
    </div>
    <q-table
      :rows="rows"
      @request="load"
      v-model:pagination="pagination"
      :columns="columns"
      row-key="name"
      flat
      class="digital-service-table"
      ref="appsTable"
      :loading="loading"
    >
      <template v-slot:no-data>
        <div class="flex full-width column items-center q-py-lg">
          <q-icon name="hide_source" class="q-mb-sm" size="60px" color="primary"></q-icon>
          <span class="block" style="font-size: 14px">No Digital Services.</span>
        </div>
      </template>
      <template v-slot:header-cell-doraScore="props">
        <q-th :props="props" class="text-left sortable">
          dora score
          <q-icon push @click.stop name="info" size="xs">
            <q-popup-proxy>
              <q-banner class="dora-legend" style="width: 400px">
                <div class="dora-legend-items">
                  <div>
                    <b>Deployment Frequency</b>
                    <ul>
                      <li>{{ '< Quarterly = 1 = LOW' }}</li>
                      <li>{{ '< Monthly = 2 = MEDIUM' }}</li>
                      <li>{{ '< Weekly = 3 = HIGH' }}</li>
                      <li>{{ '< Daily = 4 = ELITE' }}</li>
                    </ul>
                  </div>
                  <div>
                    <b>Lead Time for Changes</b>
                    <ul>
                      <li>{{ '< 1 Quarter = 1 = LOW' }}</li>
                      <li>{{ '< 1 Month = 2 = MEDIUM' }}</li>
                      <li>{{ '< 1 Week = 3 = HIGH' }}</li>
                      <li>{{ '< 1 Day = 4 = ELITE' }}</li>
                    </ul>
                  </div>
                  <div>
                    <b>Time to Restore Service</b>
                    <ul>
                      <li>{{ '> 1 Week = 1 = LOW' }}</li>
                      <li>{{ '< 1 Week = 2 = MEDIUM' }}</li>
                      <li>{{ '< 1 Day = 3 = HIGH' }}</li>
                      <li>{{ '< 1 Hour = 4 = ELITE' }}</li>
                    </ul>
                  </div>
                  <div>
                    <b>Change Failure Rate</b>
                    <ul>
                      <li>{{ '> 25% = 1 = LOW' }}</li>
                      <li>{{ '< 25% = 2 = MEDIUM' }}</li>
                      <li>{{ '< 15% = 3 = HIGH' }}</li>
                      <li>{{ '< 5% = 4 = ELITE' }}</li>
                    </ul>
                  </div>
                </div>
                <p class="q-mb-none">
                  The acronym, DORA stands for The DevOps Research and Assessment Team. Within a seven-year program ,
                  this Google research group analyzed DevOps practices and capabilities and has been able to identity
                  four key metrics to measure software development and delivery deployments.
                </p>
              </q-banner>
            </q-popup-proxy>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-kpi="props">
        <q-th :props="props" class="text-left sortable">
          Cost (USD) w/o VAT
          <q-icon push @click.stop name="info" size="xs">
            <q-popup-proxy>
              <q-banner class="dora-legend" style="width: 400px">
                <span>
                  The amount (USD) is the accumulation of the application costs in the past calendar month. The trend
                  indicator (arrow) shows the increase or decrease of the cost in comparison to the month preceding this
                  accumulated number.
                </span>
              </q-banner>
            </q-popup-proxy>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" @click="goToApplication($event, props.row.apmId)">
          <q-td key="digitalServiceActive" class="items-center" :props="props">
            <span class="application-state" :class="props.row.digitalServiceActive ? 'active' : 'inactive'">
              <q-tooltip>{{ props.row.digitalServiceActive ? 'active' : 'inactive' }}</q-tooltip>
            </span>
          </q-td>
          <q-td key="name" :props="props">
            {{ props.row.name }}
          </q-td>
          <q-td key="apmId" :props="props">
            {{ props.row.apmId }}
          </q-td>
          <q-td key="itOwner" :props="props">
            {{ props.row.itOwner }}
          </q-td>
          <q-td v-if="showQualitySensorIssues" class="quality-service success" key="qualitySensorOpen" :props="props">
            <a
              v-if="props.row.qualitySensorIssues?.numberOfOpen"
              @click.stop
              :href="qualitySensorUrl"
              target="_blank"
              >{{ props.row.qualitySensorIssues?.numberOfOpen }}</a
            >
            <span v-else>0</span>
          </q-td>
          <q-td
            v-if="showQualitySensorIssues"
            class="quality-service"
            :class="props.row.qualitySensorIssues?.numberOfOverdue > 0 ? 'danger' : 'success'"
            key="qualitySensorOverdue"
            :props="props"
          >
            <a
              v-if="props.row.qualitySensorIssues?.numberOfOverdue"
              @click.stop
              :href="qualitySensorUrl"
              target="_blank"
              >{{ props.row.qualitySensorIssues?.numberOfOverdue }}</a
            >
            <span v-else>0</span>
          </q-td>
          <q-td key="majorIncidents" :props="props">
            <span
              v-if="props.row.majorIncidents?.length"
              @click.stop="showMajorIncidents(props.row.majorIncidents)"
              style="color: red"
              >{{ props.row.majorIncidents?.length }}</span
            >
            <span v-else style="color: green">0</span>
          </q-td>
          <q-td key="services" :props="props">
            <span>{{ props.row.services || 'No onboarded services' }}</span>
          </q-td>
          <q-td key="kpi" :props="props">
            <span v-if="props.row.kpi && props.row.kpi.length">{{ showKpi(props.row.kpi).total.cost.toFixed(2) }}</span>
            <span v-else>n/a</span>
            <q-icon
              v-if="showKpi(props.row.kpi).direction"
              :name="showKpi(props.row.kpi).direction"
              :color="showKpi(props.row.kpi).color"
              class="q-ml-xs"
            ></q-icon>
          </q-td>
          <q-td key="doraScore" :props="props">
            <span
              @click.stop="goToDoraPage(props.row.apmId)"
              style="font-weight: bold; cursor: pointer"
              :style="{
                color:
                  props.row.doraScore === 'HIGH'
                    ? '#4caf50'
                    : props.row.doraScore === 'MEDIUM'
                    ? '#ffec3a'
                    : props.row.doraScore === 'LOW'
                    ? '#f44436'
                    : '#35454a',
              }"
              >{{ props.row.doraScore }}</span
            >
          </q-td>
          <q-td key="actions" :props="props" @click.stop>
            <q-btn
              class="q-px-none q-py-none btn--no-hover"
              :ripple="false"
              icon="mdi-dots-vertical"
              flat
              size="md"
              rounded
            >
              <q-menu anchor="bottom middle">
                <q-list dense>
                  <q-item v-if="!owned" clickable v-close-popup @click="deleteApplication(props.row)">
                    <q-item-section avatar style="min-width: 25px">
                      <q-icon name="sym_r_delete" size="xs" />
                    </q-item-section>
                    <q-item-section>Remove from this view</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="createBanner(props.row)">
                    <q-item-section avatar style="min-width: 25px">
                      <q-icon name="mdi-message-alert-outline" size="xs" />
                    </q-item-section>
                    <q-item-section>Set banner</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog v-model="showIncidentsDialog" class="single-service-page" persistent>
      <q-card style="min-width: 1024px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Major Incidents</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="showIncidentsDialog = false"></q-btn>
        </q-toolbar>
        <q-card-section class="q-pt-none">
          <MajorIncidentsTable :incidents="majorIncidents" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import apollo from '@/library/http/apollo';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { supportCntrlClick } from '@/shared/helpers';
import * as uiConfig from '@/shared/ui-config.json';
import { BUSINESS_DOMAINS } from '@/constatns';
import MajorIncidentsTable from './MajorIncidentsTable.vue';

export default {
  name: 'DigitalServicesTable',
  BUSINESS_DOMAINS,
  components: {
    MajorIncidentsTable,
  },
  props: {
    owned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      majorIncidents: [],
      showIncidentsDialog: false,
      showFilters: false,
      filters: {
        apmId: undefined,
        name: undefined,
        businessDomain: undefined,
        digitalServiceActive: true,
        itscmCriticality: undefined,
      },
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5,
      },
      rows: [],
      loading: true,
    };
  },
  computed: {
    qualitySensorUrl() {
      return `${process.env.VUE_APP_QUALITY_SENSORS_BASE_URL}/#/issues/open`;
    },
    showQualitySensorIssues() {
      return uiConfig[process.env.VUE_APP_ENV].showQualitySensorIssues;
    },
    columns() {
      const cols = [
        {
          name: 'digitalServiceActive',
          label: 'State',
          field: 'digitalServiceActive',
          align: 'center',
        },
        {
          name: 'name',
          label: 'name',
          field: 'name',
          align: 'left',
          classes: 'service-name',
        },
        {
          name: 'apmId',
          label: 'apmId',
          field: 'apmId',
          align: 'left',
        },
        {
          name: 'itOwner',
          label: 'it owner',
          align: 'left',
        },
        {
          name: 'majorIncidents',
          label: 'Major Incident',
          align: 'center',
        },
        {
          name: 'services',
          label: 'include services',
          align: 'center',
        },
        {
          name: 'kpi',
          field: 'kpi',
          label: 'Cost (USD) w/o VAT',
          align: 'center',
        },
        {
          name: 'doraScore',
          field: 'doraScore',
          label: 'DORA Score',
          align: 'center',
        },
        {
          name: 'actions',
          label: '',
          align: 'right',
        },
      ];
      if (this.showQualitySensorIssues) {
        cols.splice(4, 0, {
          name: 'qualitySensorOpen',
          label: 'Open issues',
          align: 'center',
          field: 'qualitySensorOpen',
        });
        cols.splice(5, 0, {
          name: 'qualitySensorOverdue',
          label: 'overdue issues',
          align: 'center',
        });
      }
      return cols;
    },
  },
  methods: {
    async deleteApplication(app) {
      this.loading = true;
      await apollo.apolloClient
        .query({
          query: gql`mutation { removeDigitalServiceFromFollowedList(digitalServiceId: "${app.id}")}`,
        })
        .then(() => {
          this.reloadTable();
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        });
    },
    createBanner(service) {
      const opaLinks = {
        DEV: 'https://opa-cockpit-dev.ac-np.swissre.com',
        NONPROD: 'https://opa-cockpit-np.ac-np.swissre.com',
        PROD: 'https://opa-cockpit.ac.swissre.com',
      };
      const env = process.env.VUE_APP_ENV;
      window.open(`${opaLinks[env]}/${service.apmId.toLowerCase()}/banners/new`, '_blank');
    },
    goToDoraPage(apmId) {
      window.open(`https://wow-measure.swissre.com/apmids/${apmId}`, '_blank');
    },
    goToApplication(event, id) {
      supportCntrlClick(event, `/my-portfolio/${id.toLowerCase()}`);
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reloadTable();
      }, 1500);
    },
    showMajorIncidents(items) {
      this.majorIncidents = items;
      this.showIncidentsDialog = true;
    },
    showKpi(kpi) {
      const sortKpi = kpi ? kpi.sort((a, b) => dayjs(a.period).unix() - dayjs(b.period).unix()) : [];
      // const totalKpi = sortKpi.reduce((acc, item) => acc + item.cost, 0);
      const lastItems = sortKpi.slice(-2);
      const direction =
        sortKpi.length > 1 ? (lastItems[1].cost > lastItems[0].cost ? 'arrow_upward' : 'arrow_downward') : null;
      return {
        direction,
        color: direction === 'arrow_downward' ? 'green' : 'red',
        total: sortKpi.length ? (sortKpi.length > 1 ? sortKpi[sortKpi.length - 1] : kpi[0]) : null,
      };
    },
    reloadTable() {
      this.$refs.appsTable.requestServerInteraction();
    },
    async load(props) {
      this.loading = true;
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
      const startRow = (page - 1) * rowsPerPage;
      this.pagination.page = page;
      this.pagination.rowsPerPage = rowsPerPage;
      this.pagination.sortBy = sortBy;
      this.pagination.descending = descending;
      await apollo.apolloClient
        .query({
          query: gql`
          query ${this.owned ? 'getUserOwnDigitalServices' : 'getFollowedDigitalServices'}(
            $name: String,
            $apmId: String,
            $digitalServiceActive: Boolean,
            $businessDomain: String,
            $itscmCriticality: String,
          ) {
            ${
              this.owned ? 'getUserOwnDigitalServices' : 'getFollowedDigitalServices'
            }(filter: {name: $name, businessDomain: $businessDomain, itscmCriticality: $itscmCriticality,  apmId: $apmId, digitalServiceActive: $digitalServiceActive}, limit: ${fetchCount}, skip: ${startRow}) {
              count
              data {
                majorIncidents
                id
                apmId
                digitalServiceName
                digitalServiceActive
                itOwner {
                  name
                }
                updatedAt
                createdAt
                doraScore
                totalKPICost
                registrations
                qualitySensorIssues {
                  numberOfOpen
                  numberOfOverdue
                }
              }
            }
          }
        `,
          variables: {
            name: this.filters?.name,
            apmId: this.filters?.apmId,
            digitalServiceActive: this.filters?.active,
            businessDomain: this.filters?.businessDomain,
            itscmCriticality: this.filters?.itscmCriticality,
          },
        })
        .then((response) => {
          const rawApplications =
            response.data[this.owned ? 'getUserOwnDigitalServices' : 'getFollowedDigitalServices'].data || [];
          const applications = this.buildApplications(rawApplications);

          this.pagination.rowsNumber =
            response.data[this.owned ? 'getUserOwnDigitalServices' : 'getFollowedDigitalServices'].count;
          this.rows = applications;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addDigitalService(app) {
      this.loading = true;
      await apollo.apolloClient
        .query({
          query: gql`mutation { addDigitalServiceToFollowedList(digitalServiceId: "${app.id}"){
            majorIncidents
            id
            apmId
            digitalServiceName
            digitalServiceActive
            itOwner {
              name
            }
            updatedAt
            createdAt
            doraScore
            totalKPICost
            registrations
            qualitySensorIssues {
              numberOfOpen
              numberOfOverdue
            }
          }}`,
        })
        .then(async (response) => {
          const applications = this.buildApplications([response.data.addDigitalServiceToFollowedList]);
          this.rows.splice(0, 1, applications[0]);
          this.pagination.rowsNumber += 1;
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildApplications(apps) {
      const applications = [];
      apps.forEach((app) => {
        const appObject = {
          id: app.id,
          apmId: app.apmId,
          name: app?.digitalServiceName,
          status: app?.digitalServiceStatus,
          itOwner: app?.itOwner?.name || '',
          createdBy: app.createdBy || '',
          digitalServiceActive: app.digitalServiceActive,
          kpi: app?.totalKPICost || [],
          services: app.registrations,
          doraScore: app.doraScore || 'No Data',
          qualitySensorIssues: app.qualitySensorIssues,
          majorIncidents: app.majorIncidents,
        };
        applications.push(appObject);
      });
      return applications;
    },
  },
  mounted() {
    this.reloadTable();
  },
  created() {
    emitter.on('loadAddingService', async (value) => {
      if (!this.owned) {
        await this.addDigitalService(value);
      }
    });
    emitter.on('loadRemoveService', async (value) => {
      await this.deleteApplication(value);
    });
  },
  unmounted() {
    emitter.off('loadAddingService');
    emitter.off('loadRemoveService');
  },
};
</script>

<style scoped lang="scss">
.digital-services-holder {
  border-radius: 15px;
}
.infra-services-holder {
  .infra-service {
    margin-bottom: 5px;
  }
}
.dora-legend {
  font-size: 12px;
  line-height: 1.3;

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }
}
.dora-legend-items {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 50%;
  }
}
.application-state {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $grey-5;

  &.active {
    background-color: $green-5;
  }
}
.service-name {
  max-width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.status {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: $grey-6;

  &.inprogress {
    background-color: $orange;
  }

  &.pending {
    background-color: $grey-6;
  }

  &.completed,
  &.abandoned {
    background-color: $green;
  }

  &.failed {
    background-color: $red;
  }

  &.reverted {
    background-color: #d00fa0;
  }
}
.table-header {
  h5 {
    font-size: 20px;
    letter-spacing: 0.005em;
    font-weight: 400;
    margin: 12px 16px 12px 6px;
  }
}
.quality-service {
  &.success {
    color: $green;
  }

  &.danger {
    color: $red;
  }

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
