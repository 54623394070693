<template>
  <q-btn
    flat
    round
    :disabled="loading"
    :loading="loading"
    size="12px"
    style="width: 30px; height: 40px"
    class="btn--no-hover"
    @click="$emit('showWorkflow', $event)"
  >
    <span class="relative">
      <q-icon name="sym_r_work"></q-icon>
      <span class="clock">
        <q-icon name="watch_later" :color="iconColor" size="10px"></q-icon>
      </span>
    </span>
    <q-tooltip>Show related workflows</q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'WorkflowsBtn',
  props: {
    relatedWorkflowStatuses: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      let color = 'black';
      if (
        this.relatedWorkflowStatuses.includes('error') ||
        this.relatedWorkflowStatuses.includes('incident') ||
        this.relatedWorkflowStatuses.includes('not started')
      ) {
        color = 'red';
      } else if (this.relatedWorkflowStatuses.includes('pending')) {
        color = 'yellow-7';
      } else if (this.relatedWorkflowStatuses.includes('inprogress')) {
        color = 'orange';
      }
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.clock {
  background-color: #fff;
  position: absolute;
  width: 10px;
  height: 10px;
  right: 6px;
  bottom: 8px;
  display: flex;
  border-radius: 50%;
}
</style>
