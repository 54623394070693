<template>
  <div class="flex items-center q-mb-xs">
    <span class="label-block text-bold"
      >{{ firewallRulesType === 'source' ? 'Source APMID' : 'Destination APMID' }}
      {{ registrationData.digitalServiceApm }}:</span
    >
    <q-btn
      class="filter-icon q-ml-auto btn--no-hover"
      :class="{ active: showFilters }"
      icon="filter_alt"
      flat
      :ripple="false"
      @click="showFilters = !showFilters"
    >
      <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
    </q-btn>
    <q-btn
      v-if="mode === 'read'"
      flat
      size="md"
      style="width: 30px"
      icon="edit"
      class="btn--no-hover"
      @click="goToRegistration($event)"
    >
      <q-tooltip>Edit firewall rules</q-tooltip>
    </q-btn>
  </div>
  <q-table
    :columns="fireWallRulesCols"
    flat
    :rows="rows"
    class="external-dns-table"
    ref="firewallRulesTable"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    binary-state-sort
    horizontal-scroll
    :rows-per-page-options="[5, 7, 10, 15, 20]"
    @request="loadFirewallRules"
  >
    <template v-slot:no-data>
      <div class="flex full-width column items-center q-py-lg">
        <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 14px">No items were found.</span>
      </div>
    </template>
    <template v-slot:body-cell-changeLink="props">
      <q-td :props="props">
        <a v-if="props.row.changeLink" target="_blank" :href="props.row.changeLink">{{
          props.row.changeNumber || 'Change Request'
        }}</a>
        <span v-else>-</span>
      </q-td>
    </template>
    <template v-slot:body-cell-comment="props">
      <q-td :props="props">
        <span v-if="props.row.comment?.length" :class="{ comment: props.row?.comment?.length > 20 }"
          >{{ props.row.comment }}
          <q-tooltip v-if="props.row?.comment?.length > 20">
            {{ props.row.comment }}
          </q-tooltip></span
        >
        <span v-else>-</span>
      </q-td>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <StatusCell v-if="!props.row.deletedAt" :status="props.row.status" />
        <q-icon v-else name="close" color="grey" size="15px"></q-icon>
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <WorkflowsBtn
          @showWorkflow="showWorkflow($event, props.row)"
          :loading="loading"
          :relatedWorkflowStatuses="relatedWorkflowStatuses.find((i) => i.id === props.row.id)?.statuses || []"
        />
        <q-btn
          flat
          round
          size="10px"
          style="width: 30px; height: 40px"
          class="btn--no-hover"
          :ripple="false"
          icon="engp:engp-ico-copy"
          @click.stop="copyUuid(props.row.id)"
        >
          <q-tooltip>Copy uuid</q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:top>
      <div v-if="showFilters" class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
        <div class="col">
          <q-select
            v-model="filters.status"
            :options="[
              { label: 'Completed', value: 'completed' },
              { label: 'In progress', value: 'inprogress' },
              { label: 'Failed', value: 'failed' },
              { label: 'Removed', value: 'removed' },
            ]"
            emit-value
            map-options
            options-dense
            @update:model-value="reloadFirewallRulesTable()"
            @clear="reloadFirewallRulesTable()"
            label="Status"
            clearable
            outlined
            dense
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.port"
            label="Port"
            outlined
            dense
            clearable
            @update:model-value="filterTable()"
            @clear="reloadFirewallRulesTable()"
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-select
            v-model="filters.protocol"
            :options="['UDP', 'TCP']"
            options-dense
            label="Protocol"
            outlined
            dense
            clearable
            @update:model-value="reloadFirewallRulesTable()"
            @clear="reloadFirewallRulesTable()"
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.srcIp"
            label="SRC IP"
            outlined
            dense
            clearable
            @update:model-value="filterTable()"
            @clear="reloadFirewallRulesTable()"
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-input
            v-model="filters.dstIp"
            label="DST IP"
            outlined
            dense
            clearable
            @update:model-value="filterTable()"
            @clear="reloadFirewallRulesTable()"
            class="q-mb-sm"
          ></q-input>
        </div>
      </div>
    </template>
  </q-table>
</template>

<script>
import dayjs from 'dayjs';
import emitter from 'tiny-emitter/instance';
import { copyToClipboard } from 'quasar';
import StatusCell from './StatusCell.vue';
import { getFirewallRules, getRelatedWorkflowsStatuses } from '@/pages/registrations/api/api';
import { supportCntrlClick } from '@/shared/helpers';
import WorkflowsBtn from '@/shared/components/WorkflowsBtn.vue';

export default {
  name: 'FirewallRules',
  components: {
    StatusCell,
    WorkflowsBtn,
  },
  props: {
    registrationData: {
      type: Object,
    },
    firewallRulesType: {
      type: String,
    },
    registrationId: {
      type: String,
    },
    mode: {
      type: String,
      default: () => 'read',
    },
  },
  computed: {
    fireWallRulesCols() {
      const cols = [
        {
          label: 'Status',
          name: 'status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Source IP',
          name: 'srcIp',
          field: 'srcIp',
          align: 'left',
        },
        {
          label: 'Destination IP',
          name: 'dstIp',
          field: 'dstIp',
          align: 'left',
        },
        {
          label: 'Protocol',
          name: 'protocol',
          field: 'protocol',
          align: 'left',
        },
        {
          label: 'Port',
          name: 'port',
          field: 'port',
          align: 'left',
        },
        {
          label: 'Comment',
          name: 'comment',
          field: 'comment',
          align: 'center',
        },
        {
          label: 'Created At',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Updated At',
          name: 'updatedAt',
          field: 'updatedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Change Request',
          name: 'changeLink',
          field: 'changeLink',
          align: 'center',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'right',
        },
      ];
      if (this.firewallRulesType === 'source') {
        cols.splice(1, 0, {
          label: 'Destination APM ID',
          name: 'dstApmId',
          field: 'dstApmId',
          align: 'left',
        });
        if (this.filters.status === 'removed') {
          cols.splice(9, 0, {
            label: 'Deleted At',
            name: 'deletedAt',
            field: 'deletedAt',
            align: 'left',
            format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          });
        }
      }
      if (this.firewallRulesType === 'destination') {
        cols.splice(1, 0, {
          label: 'Source APM ID',
          name: 'srcApmId',
          field: 'srcApmId',
          align: 'left',
        });
        cols.splice(7, 0, {
          label: 'Requested By',
          name: 'requestedBy',
          field: 'requestedBy',
          align: 'left',
        });
        if (this.filters.status === 'removed') {
          cols.splice(10, 0, {
            label: 'Deleted At',
            name: 'deletedAt',
            field: 'deletedAt',
            align: 'left',
            format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          });
        }
      }
      return cols;
    },
  },
  data() {
    return {
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
      filters: {
        port: undefined,
        protocol: undefined,
        srcIp: undefined,
        dstIp: undefined,
        status: undefined,
      },
      rows: [],
      loading: false,
      showFilters: false,
      timeout: undefined,
      relatedWorkflowStatuses: [],
    };
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Firewall rule UUID was copied');
      });
    },
    goToRegistration(event) {
      supportCntrlClick(
        event,
        `/my-portfolio/${this.registrationData.digitalServiceApm}/registration/${this.registrationId}`,
      );
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reloadFirewallRulesTable();
      }, 1500);
    },
    showWorkflow(event, record) {
      supportCntrlClick(event, `/workflows?itemId=${record.id}`);
    },
    async loadFirewallRules(props) {
      this.loading = true;
      try {
        this.relatedWorkflowStatuses = [];
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { port, protocol, srcIp, dstIp, status } = this.filters;
        const srcApmId = this.firewallRulesType === 'source' ? this.registrationData.digitalServiceApm : undefined;
        const dstApmId = this.firewallRulesType === 'destination' ? this.registrationData.digitalServiceApm : undefined;
        const { count, data } = await getFirewallRules(
          limit,
          skip,
          sortBy,
          srcApmId,
          dstApmId,
          descending,
          port,
          protocol,
          srcIp,
          dstIp,
          status,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.pagination.page = props.pagination.page;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
        const relatedWorkflowsPrmises = this.rows.map((record) => {
          return getRelatedWorkflowsStatuses(record.id).then((response) => {
            this.relatedWorkflowStatuses.push({ id: record.id, statuses: response });
          });
        });
        await Promise.all([...relatedWorkflowsPrmises]);
      } finally {
        this.loading = false;
      }
    },
    reloadFirewallRulesTable() {
      this.$refs.firewallRulesTable.requestServerInteraction();
    },
  },
  mounted() {
    this.reloadFirewallRulesTable();
  },
  created() {
    emitter.on('reloadFirewallRules', () => {
      this.reloadFirewallRulesTable();
    });
  },
  unmounted() {
    emitter.off('reloadFirewallRules');
  },
};
</script>

<style lang="scss" scoped>
.comment {
  display: block;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
