<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <section class="widget-section q-mb-lg">
      <EnvRegistration :registrationId="registrationId" :registrationData="registrationData" :envs="envs" mode="edit" :additionalSchemaProperties="additionalSchemaProperties" :additionalUiSchemaElements="additionalUiSchemaElements"  />
    </section>
  </q-page>
</template>

<script>
import EnvRegistration from './EnvRegistration.vue';

export default {
  name: 'RegistrationPostman',
  components: {
    EnvRegistration,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      additionalSchemaProperties: {
        reason: {
          type: 'string',
          minLength: 10
        }
      },
      additionalUiSchemaElements: [
        {
          type: 'Control',
          label: 'Reason',
          scope: '#/properties/reason',
        }
      ],
      envs: ['PROD'],
    };
  },
};
</script>
