import { getRoles } from '@/pages/registrations/api/api';

export const ROLES_GETTERS = {
  ADMIN_ROLES: 'adminRoles',
  CONTRIBUTOR_ROLES: 'contributorRoles',
  READER_ROLES: 'readerRoles',
};

export const ROLES_ACTIONS = {
  GET_ROLES: 'getRoles',
};

const ROLES_MUTATIONS = {
  SET_ADMIN_ROLES: 'setAdminRoles',
  SET_CONTRIBUTOR_ROLES: 'setContributorRoles',
  SET_READER_ROLES: 'setReaderRoles',
};

export default {
  namespaced: true,
  state: {
    adminRoles: [],
    contributorRoles: [],
    readerRoles: [],
  },
  getters: {
    [ROLES_GETTERS.ADMIN_ROLES]: (state: any) => {
      return state.adminRoles;
    },
    [ROLES_GETTERS.CONTRIBUTOR_ROLES]: (state: any) => {
      return state.contributorRoles;
    },
    [ROLES_GETTERS.READER_ROLES]: (state: any) => {
      return state.readerRoles;
    },
  },
  mutations: {
    [ROLES_MUTATIONS.SET_ADMIN_ROLES]: (state: any, roles: any) => {
      state.adminRoles = roles;
    },
    [ROLES_MUTATIONS.SET_CONTRIBUTOR_ROLES]: (state: any, roles: any) => {
      state.contributorRoles = roles;
    },
    [ROLES_MUTATIONS.SET_READER_ROLES]: (state: any, roles: any) => {
      state.readerRoles = roles;
    },
  },
  actions: {
    [ROLES_ACTIONS.GET_ROLES]: async (context: any, role: string) => {
      const data = await getRoles(role);
      const roles = [...new Set(data.map((i: any) => i.displayName))];
      if (role === 'ProjAdmin') {
        context.commit(ROLES_MUTATIONS.SET_ADMIN_ROLES, roles);
      } else if (role === 'ProjContributor') {
        context.commit(ROLES_MUTATIONS.SET_CONTRIBUTOR_ROLES, roles);
      } else if (role === 'ProjReader') {
        context.commit(ROLES_MUTATIONS.SET_READER_ROLES, roles);
      }
    },
  },
};
