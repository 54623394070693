<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div v-if="loadData" class="widget-section">
      <div class="flex items-center justify-between q-mb-md block-label">
        <span class="label-block">Environments:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-md btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          style="min-height: 30px"
          :disabled="addEnvError?.length || addLoading"
          :loading="addLoading"
          :ripple="false"
          @click="showEnvForm('create')"
        >
          + Add Environment
          <q-tooltip v-if="addEnvError?.length">{{ addEnvError }}</q-tooltip>
        </q-btn>
      </div>

      <div v-if="showForm" class="env-form">
        <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mb-sm">
          <span class="flex items-center" v-for="(error, key) in generalErrors" :key="key">
            <q-icon name="error" class="q-mr-xs" size="16px"></q-icon>{{ error.message }}
          </span>
        </q-banner>
        <div class="flex full-width wrap">
          <JsonForms
            :renderers="renderers"
            :schema="schema"
            :data="formData"
            :uischema="uiSchema"
            :additionalErrors="errors"
            validationMode="ValidateAndShow"
            @change="validateForm"
          />
          <div class="btns flex items-center q-mt-sm q-ml-sm">
            <q-btn
              @click="sendData"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(errors.length) ||
                Boolean(generalErrors.length)
              "
              no-caps
              text-color="dark"
              color="primary"
              >Save</q-btn
            >
            <q-btn @click="closeForm" :ripple="false" class="btn--no-hover" no-caps flat>Cancel</q-btn>
          </div>
        </div>
      </div>
      <ServiceBusEnv
        :environments="environments"
        :storedEnvironments="storedEnvironments()"
        :disableEditEnvs="disableEditEnvs"
        :relatedWorkflowStatuses="relatedWorkflowStatuses"
        mode="edit"
        @edit="edit($event)"
      />
    </div>
  </q-page>
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import {
  getRegistration,
  getBluePrint,
  getRelatedWorkflowsStatuses,
  validateWorkFlow,
  createWorkFlow,
  subValWorkflow,
} from '../../api/api';
import { supportCntrlClick } from '@/shared/helpers';
import ServiceBusEnv from './components/ServiceBusEnv.vue';
import { mapActions } from 'vuex';
import { ROLES_ACTIONS } from '@/store/modules/roles';

const renderers = [...quasarRenderers];

export default {
  name: 'RegistrationServiceBus',
  components: {
    JsonForms,
    ServiceBusEnv,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      addLoading: true,
      renderers: Object.freeze(renderers),
      showForm: false,
      loadData: false,
      addEnvError: undefined,
      schema: {},
      formErrors: [],
      errors: [],
      generalErrors: [],
      environments: [],
      loadSend: false,
      submitDisable: true,
      relatedWorkflowStatuses: [],
      validationTimeout: undefined,
      formData: {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      disableEditEnvs: {},
      action: undefined,
      uiSchema: {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            label: 'Environment',
            scope: '#/properties/environmentId',
          },
          {
            type: 'HorizontalLayout',
            options: {
              alignment: 'start',
            },
            elements: [
              {
                type: 'Control',
                label: 'Admin roles',
                scope: '#/properties/adminRole',
                options: {
                  itemLabel: 'Type in our apmid to search',
                  customRenderer: 'CustomEnumControl',
                  key: 'ProjAdmin',
                },
              },
              {
                type: 'Control',
                label: 'Contributor roles',
                scope: '#/properties/contributorRole',
                options: {
                  itemLabel: 'Type in our apmid to search',
                  customRenderer: 'CustomEnumControl',
                  key: 'ProjContributor',
                },
              },
              {
                type: 'Control',
                label: 'Reader roles',
                scope: '#/properties/readerRole',
                options: {
                  itemLabel: 'Type in our apmid to search',
                  customRenderer: 'CustomEnumControl',
                  key: 'ProjReader',
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions('roles', {
      getRoles: ROLES_ACTIONS.GET_ROLES,
    }),
    showEnvForm(action) {
      this.action = action;
      this.showForm = true;
    },
    edit(env) {
      this.formData.environmentId = env.value;
      this.formData.adminRole = env?.details?.adminRole || [];
      this.formData.contributorRole = env?.details?.contributorRole || [];
      this.formData.readerRole = env?.details?.readerRole || [];
      this.showEnvForm('edit');
    },
    async sendData() {
      try {
        const workflowData = {
          workflowId: 'engprt.onboard-application-azmghost',
          inputVariables: this.formData,
        };
        this.loadSend = true;
        await createWorkFlow(workflowData);
        this.$notify('positive', 'Success', `Environment will be ${this.action === 'create' ? 'added' : 'updated'}.`);
        let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};

        storedData[this.registrationId] = storedData[this.registrationId] || [];
        if (!storedData[this.registrationId].includes(this.formData.environmentId)) {
          storedData[this.registrationId].push(this.formData.environmentId);
        }
        localStorage.setItem('registrationData', JSON.stringify(storedData));

        setTimeout(async () => {
          await this.load();
          this.closeForm();
        }, 2000);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadSend = false;
      }
    },
    closeForm() {
      if (this.validationTimeout) clearTimeout(this.validationTimeout);
      this.showForm = false;
      this.action = undefined;
      this.formData = {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      };
      this.formErrors = [];
      this.errors = [];
      this.generalErrors = [];
    },
    validateForm(value) {
      this.formErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          try {
            const workFlowData = {
              workflowId: 'engprt.onboard-application-azmghost',
              inputVariables: this.formData,
            };
            await validateWorkFlow(workFlowData);
            this.errors = [];
            this.generalErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.errors = [];
              this.generalErrors = [];
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loadSend = false;
          }
        }, 3000);
      }
    },
    showWorkflow(event, id) {
      supportCntrlClick(event, `/workflows?itemId=${id}`);
    },
    storedEnvironments() {
      const storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
      return storedData[this.registrationId] || [];
    },
    async load() {
      this.$showLoading();
      this.loadData = false;
      try {
        const data = await getRegistration(this.registrationId, true, true);

        this.environments = data.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));

        this.environments.forEach((env) => {
          if (data.details?.length) {
            env.details = data.details.find((i) => i.environment === env.value);
          }
        });

        let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
        let storedEnvs = storedData[this.registrationId] || [];

        storedData[this.registrationId] = storedEnvs.filter((env) => !this.environments.some((e) => e.value === env));

        if (storedData[this.registrationId].length === 0) {
          delete storedData[this.registrationId];
        }

        localStorage.setItem('registrationData', JSON.stringify(storedData));

        this.uiSchema.elements[0].options = {
          disabledOptions: [...this.environments.map((env) => env.value), ...this.storedEnvironments()],
        };

        const relatedWorkflowsPrmises = this.environments.map(async (env) => {
          return getRelatedWorkflowsStatuses(env.id).then((response) => {
            this.relatedWorkflowStatuses.push({ id: env.id, statuses: response });
          });
        });
        const editPromises = this.environments.map(async (env) => {
          return subValWorkflow({
            workflowId: 'engprt.onboard-application-azmghost',
            inputVariables: {
              digitalServiceApmId: this.registrationData.digitalServiceApm,
              environmentId: env.value,
            },
            subSchemaId: 'edit-button',
          }).catch((error) => {
            this.disableEditEnvs[env.value] = error.message;
          });
        });
        const subValPromise = subValWorkflow({
          workflowId: 'engprt.onboard-application-azmghost',
          inputVariables: {
            digitalServiceApmId: this.registrationData.digitalServiceApm,
            infraServiceApmId: this.registrationData.infrastructureServiceApm,
          },
          subSchemaId: 'add-button',
        })
          .then(() => {
            this.addEnvError = undefined;
          })
          .catch((error) => {
            this.addEnvError = error.message;
          });

        await Promise.all([...relatedWorkflowsPrmises, ...editPromises, subValPromise]);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadData = true;
        this.$hideLoading();
      }
    },
  },
  async mounted() {
    try {
      const { inputVariablesSchema: serviceBusSchema } = await getBluePrint('engprt.onboard-application-azmghost');
      delete serviceBusSchema.$async;
      delete serviceBusSchema.$schema;
      this.schema = serviceBusSchema;
      await this.getRoles('ProjAdmin');
      await this.getRoles('ProjContributor');
      await this.getRoles('ProjReader');
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.addLoading = false;
    }
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
.label-block {
  font-size: 20px;
  font-weight: bold;
}
.btns {
  min-width: 250px;
}
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
.label-block {
  font-size: 20px;
  font-weight: bold;
}
.btns {
  min-width: 175px;
}
.env-form {
  background-color: var(--q-accent);
  padding: 18px 6px 10px;
  margin-bottom: 20px;
  border-top: 1px solid $grey-4;
  border-bottom: 1px solid $grey-4;
}
</style>
