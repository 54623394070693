
import { ROLES_GETTERS } from '@/store/modules/roles';
import { ControlElement } from '@jsonforms/core';
import { rendererProps, RendererProps, useJsonFormsEnumControl } from '@jsonforms/vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

const controlRenderer = defineComponent({
  name: 'enum-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
    itemLabel: {
      type: String,
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsEnumControl(props);
  },
  data() {
    return {
      disable: false,
      loading: false,
      options: [],
      filteredOptions: [],
    };
  },
  mounted() {
    if (!this.control.data && this.control.uischema.options?.default) {
      this.control.data = this.control.uischema.options.default;
    }
    if (this.control?.uischema?.options?.key === 'ProjAdmin') {
      this.options = this.adminRoles;
    }
    if (this.control?.uischema?.options?.key === 'ProjContributor') {
      this.options = this.contributorRoles;
    }
    if (this.control?.uischema?.options?.key === 'ProjReader') {
      this.options = this.readerRoles;
    }
    this.onChange(this.control.data);
  },
  computed: {
    ...mapGetters('roles', {
      adminRoles: ROLES_GETTERS.ADMIN_ROLES,
      contributorRoles: ROLES_GETTERS.CONTRIBUTOR_ROLES,
      readerRoles: ROLES_GETTERS.READER_ROLES,
    }),
  },
  methods: {
    filterFn(val: string, update: any) {
      if (!val || val.length < 2) {
        update(() => {
          this.filteredOptions = [];
        });
        return;
      }

      update(() => {
        const lowerVal = val.toLowerCase();
        this.filteredOptions = this.options.filter((option: string) => option.toLowerCase().includes(lowerVal));
      });
    },
    onChange(value: any) {
      this.handleChange(this.control.path, value);
    },
  },
});

export default controlRenderer;
